import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type ApiClient = {
  __typename?: 'ApiClient';
  CLIENT?: Maybe<Scalars['String']>;
  CLIENT_EMAIL?: Maybe<Scalars['String']>;
  CLIENT_PHONE?: Maybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: Maybe<Scalars['String']>;
  PM_ADDRESS?: Maybe<Scalars['String']>;
  PM_BIRTHDATE?: Maybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: Maybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  PM_EMAIL?: Maybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: Maybe<Scalars['String']>;
  PM_FIO_DRIVER?: Maybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: Maybe<Scalars['String']>;
  PM_PHONE_OWNER?: Maybe<Scalars['String']>;
  cars?: Maybe<Array<Car>>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ApiClientLink = {
  __typename?: 'ApiClientLink';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  link: ApiClient;
  linkId: Scalars['String'];
  linkType: ApiClientLinkType;
  linkTypeId: Scalars['String'];
  reverse: Scalars['String'];
  self: ApiClient;
  selfId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ApiClientLinkCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  linkId: Scalars['String'];
  linkTypeId: Scalars['String'];
  selfId: Scalars['String'];
};

export type ApiClientLinkType = {
  __typename?: 'ApiClientLinkType';
  antonymId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  limit: Scalars['Int'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ApiReport = {
  __typename?: 'ApiReport';
  fileUrl: Scalars['String'];
  name: Scalars['String'];
};

export type ApiReportDataInput = {
  name: Scalars['String'];
};

export type ApiToken = {
  __typename?: 'ApiToken';
  token: Scalars['String'];
  user: ApiUser;
};

export type ApiUser = {
  __typename?: 'ApiUser';
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory: Array<TaskStatusHistory>;
  _count?: Maybe<UserCount>;
  clients: Array<Client>;
  commentedTasks: Array<Task>;
  createdAt: Scalars['DateTime'];
  director?: Maybe<ApiUser>;
  directorId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  login: Scalars['String'];
  office: Office;
  officeId: Scalars['String'];
  roles: Array<Role>;
  staff?: Maybe<Array<ApiUser>>;
  surName?: Maybe<Scalars['String']>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type ApiUserTaskStatusHistoryArgs = {
  cursor?: InputMaybe<TaskStatusHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskStatusHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskStatusHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskStatusHistoryWhereInput>;
};


export type ApiUserClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type ApiUserCommentedTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type ApiUserRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type ApiUserStaffArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type ApiUserTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

/** Бренды */
export type Brand = {
  __typename?: 'Brand';
  _count?: Maybe<BrandCount>;
  /** Бренд */
  brand: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Связанные точки бизнеса */
  pointTypes: Array<BrandPointType>;
  updatedAt: Scalars['DateTime'];
};


/** Бренды */
export type BrandPointTypesArgs = {
  cursor?: InputMaybe<BrandPointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BrandPointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BrandPointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BrandPointTypeWhereInput>;
};

export type BrandCount = {
  __typename?: 'BrandCount';
  pointTypes: Scalars['Int'];
};

export type BrandCreateNestedOneWithoutPointTypesInput = {
  connect?: InputMaybe<BrandWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BrandCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<BrandCreateWithoutPointTypesInput>;
};

export type BrandCreateOrConnectWithoutPointTypesInput = {
  create: BrandCreateWithoutPointTypesInput;
  where: BrandWhereUniqueInput;
};

export type BrandCreateWithoutPointTypesInput = {
  brand: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandOrderByWithRelationInput = {
  brand?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pointTypes?: InputMaybe<BrandPointTypeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

/** Создаваемые в зависимости от бренда точки бизнеса */
export type BrandPointType = {
  __typename?: 'BrandPointType';
  /** Бренд */
  brand: Brand;
  /** ID бренда */
  brandId: Scalars['String'];
  /** Условие создания точки бизнеса */
  createCondition?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  /** Дней до события */
  days?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  /** Пробег до события */
  mileage?: Maybe<Scalars['Int']>;
  /** Модель */
  model: CarModel;
  /** ID модели */
  modelId: Scalars['String'];
  name: PointName;
  /** Точка бизнеса */
  pointType: PointType;
  /** ID точки бизнеса */
  pointTypeId: Scalars['String'];
  /** ID зависимой точки бизнеса */
  slave?: Maybe<Scalars['String']>;
  /** Зависимая точка бизнеса */
  slavePointType?: Maybe<PointType>;
  updatedAt: Scalars['DateTime'];
};

export type BrandPointTypeBrandIdModelIdNamePointTypeIdCompoundUniqueInput = {
  brandId: Scalars['String'];
  modelId: Scalars['String'];
  name: PointName;
  pointTypeId: Scalars['String'];
};

export type BrandPointTypeCreateManyPointTypeInput = {
  brandId: Scalars['String'];
  createCondition?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  days?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  modelId: Scalars['String'];
  name: PointName;
  slave?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandPointTypeCreateManyPointTypeInputEnvelope = {
  data: Array<BrandPointTypeCreateManyPointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BrandPointTypeCreateManySlavePointTypeInput = {
  brandId: Scalars['String'];
  createCondition?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  days?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  modelId: Scalars['String'];
  name: PointName;
  pointTypeId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandPointTypeCreateManySlavePointTypeInputEnvelope = {
  data: Array<BrandPointTypeCreateManySlavePointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BrandPointTypeCreateNestedManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandPointTypeCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<BrandPointTypeCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<BrandPointTypeCreateManyPointTypeInputEnvelope>;
};

export type BrandPointTypeCreateNestedManyWithoutSlavePointTypeInput = {
  connect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandPointTypeCreateOrConnectWithoutSlavePointTypeInput>>;
  create?: InputMaybe<Array<BrandPointTypeCreateWithoutSlavePointTypeInput>>;
  createMany?: InputMaybe<BrandPointTypeCreateManySlavePointTypeInputEnvelope>;
};

export type BrandPointTypeCreateOrConnectWithoutPointTypeInput = {
  create: BrandPointTypeCreateWithoutPointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeCreateOrConnectWithoutSlavePointTypeInput = {
  create: BrandPointTypeCreateWithoutSlavePointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeCreateWithoutPointTypeInput = {
  brand: BrandCreateNestedOneWithoutPointTypesInput;
  createCondition?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  days?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  model: CarModelCreateNestedOneWithoutPointTypesInput;
  name: PointName;
  slavePointType?: InputMaybe<PointTypeCreateNestedOneWithoutSlaveBrandsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandPointTypeCreateWithoutSlavePointTypeInput = {
  brand: BrandCreateNestedOneWithoutPointTypesInput;
  createCondition?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  days?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  model: CarModelCreateNestedOneWithoutPointTypesInput;
  name: PointName;
  pointType: PointTypeCreateNestedOneWithoutBrandsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BrandPointTypeListRelationFilter = {
  every?: InputMaybe<BrandPointTypeWhereInput>;
  none?: InputMaybe<BrandPointTypeWhereInput>;
  some?: InputMaybe<BrandPointTypeWhereInput>;
};

export type BrandPointTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BrandPointTypeOrderByWithRelationInput = {
  brand?: InputMaybe<BrandOrderByWithRelationInput>;
  brandId?: InputMaybe<SortOrder>;
  createCondition?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  days?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mileage?: InputMaybe<SortOrder>;
  model?: InputMaybe<CarModelOrderByWithRelationInput>;
  modelId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  pointType?: InputMaybe<PointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrder>;
  slave?: InputMaybe<SortOrder>;
  slavePointType?: InputMaybe<PointTypeOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum BrandPointTypeScalarFieldEnum {
  BrandId = 'brandId',
  CreateCondition = 'createCondition',
  CreatedAt = 'createdAt',
  Days = 'days',
  Id = 'id',
  Mileage = 'mileage',
  ModelId = 'modelId',
  Name = 'name',
  PointTypeId = 'pointTypeId',
  Slave = 'slave',
  UpdatedAt = 'updatedAt'
}

export type BrandPointTypeScalarWhereInput = {
  AND?: InputMaybe<Array<BrandPointTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<BrandPointTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<BrandPointTypeScalarWhereInput>>;
  brandId?: InputMaybe<StringFilter>;
  createCondition?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  days?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mileage?: InputMaybe<IntNullableFilter>;
  modelId?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumPointNameFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  slave?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BrandPointTypeUpdateManyMutationInput = {
  createCondition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  days?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mileage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumPointNameFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BrandPointTypeUpdateManyWithWhereWithoutPointTypeInput = {
  data: BrandPointTypeUpdateManyMutationInput;
  where: BrandPointTypeScalarWhereInput;
};

export type BrandPointTypeUpdateManyWithWhereWithoutSlavePointTypeInput = {
  data: BrandPointTypeUpdateManyMutationInput;
  where: BrandPointTypeScalarWhereInput;
};

export type BrandPointTypeUpdateManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandPointTypeCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<BrandPointTypeCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<BrandPointTypeCreateManyPointTypeInputEnvelope>;
  delete?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BrandPointTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<BrandPointTypeUpdateWithWhereUniqueWithoutPointTypeInput>>;
  updateMany?: InputMaybe<Array<BrandPointTypeUpdateManyWithWhereWithoutPointTypeInput>>;
  upsert?: InputMaybe<Array<BrandPointTypeUpsertWithWhereUniqueWithoutPointTypeInput>>;
};

export type BrandPointTypeUpdateManyWithoutSlavePointTypeInput = {
  connect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BrandPointTypeCreateOrConnectWithoutSlavePointTypeInput>>;
  create?: InputMaybe<Array<BrandPointTypeCreateWithoutSlavePointTypeInput>>;
  createMany?: InputMaybe<BrandPointTypeCreateManySlavePointTypeInputEnvelope>;
  delete?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BrandPointTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<BrandPointTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<BrandPointTypeUpdateWithWhereUniqueWithoutSlavePointTypeInput>>;
  updateMany?: InputMaybe<Array<BrandPointTypeUpdateManyWithWhereWithoutSlavePointTypeInput>>;
  upsert?: InputMaybe<Array<BrandPointTypeUpsertWithWhereUniqueWithoutSlavePointTypeInput>>;
};

export type BrandPointTypeUpdateWithWhereUniqueWithoutPointTypeInput = {
  data: BrandPointTypeUpdateWithoutPointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeUpdateWithWhereUniqueWithoutSlavePointTypeInput = {
  data: BrandPointTypeUpdateWithoutSlavePointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeUpdateWithoutPointTypeInput = {
  brand?: InputMaybe<BrandUpdateOneRequiredWithoutPointTypesInput>;
  createCondition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  days?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mileage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  model?: InputMaybe<CarModelUpdateOneRequiredWithoutPointTypesInput>;
  name?: InputMaybe<EnumPointNameFieldUpdateOperationsInput>;
  slavePointType?: InputMaybe<PointTypeUpdateOneWithoutSlaveBrandsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BrandPointTypeUpdateWithoutSlavePointTypeInput = {
  brand?: InputMaybe<BrandUpdateOneRequiredWithoutPointTypesInput>;
  createCondition?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  days?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mileage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  model?: InputMaybe<CarModelUpdateOneRequiredWithoutPointTypesInput>;
  name?: InputMaybe<EnumPointNameFieldUpdateOperationsInput>;
  pointType?: InputMaybe<PointTypeUpdateOneRequiredWithoutBrandsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BrandPointTypeUpsertWithWhereUniqueWithoutPointTypeInput = {
  create: BrandPointTypeCreateWithoutPointTypeInput;
  update: BrandPointTypeUpdateWithoutPointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeUpsertWithWhereUniqueWithoutSlavePointTypeInput = {
  create: BrandPointTypeCreateWithoutSlavePointTypeInput;
  update: BrandPointTypeUpdateWithoutSlavePointTypeInput;
  where: BrandPointTypeWhereUniqueInput;
};

export type BrandPointTypeWhereInput = {
  AND?: InputMaybe<Array<BrandPointTypeWhereInput>>;
  NOT?: InputMaybe<Array<BrandPointTypeWhereInput>>;
  OR?: InputMaybe<Array<BrandPointTypeWhereInput>>;
  brand?: InputMaybe<BrandRelationFilter>;
  brandId?: InputMaybe<StringFilter>;
  createCondition?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  days?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mileage?: InputMaybe<IntNullableFilter>;
  model?: InputMaybe<CarModelRelationFilter>;
  modelId?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumPointNameFilter>;
  pointType?: InputMaybe<PointTypeRelationFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  slave?: InputMaybe<StringNullableFilter>;
  slavePointType?: InputMaybe<PointTypeRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BrandPointTypeWhereUniqueInput = {
  brandId_modelId_name_pointTypeId?: InputMaybe<BrandPointTypeBrandIdModelIdNamePointTypeIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type BrandRelationFilter = {
  is?: InputMaybe<BrandWhereInput>;
  isNot?: InputMaybe<BrandWhereInput>;
};

export type BrandUpdateOneRequiredWithoutPointTypesInput = {
  connect?: InputMaybe<BrandWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BrandCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<BrandCreateWithoutPointTypesInput>;
  update?: InputMaybe<BrandUpdateWithoutPointTypesInput>;
  upsert?: InputMaybe<BrandUpsertWithoutPointTypesInput>;
};

export type BrandUpdateWithoutPointTypesInput = {
  brand?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BrandUpsertWithoutPointTypesInput = {
  create: BrandCreateWithoutPointTypesInput;
  update: BrandUpdateWithoutPointTypesInput;
};

export type BrandWhereInput = {
  AND?: InputMaybe<Array<BrandWhereInput>>;
  NOT?: InputMaybe<Array<BrandWhereInput>>;
  OR?: InputMaybe<Array<BrandWhereInput>>;
  brand?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  pointTypes?: InputMaybe<BrandPointTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BrandWhereUniqueInput = {
  brand?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Car = {
  __typename?: 'Car';
  BRAND?: Maybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: Maybe<Scalars['String']>;
  SALESMAN?: Maybe<Scalars['String']>;
  _count?: Maybe<CarCount>;
  client: Client;
  clientId: Scalars['String'];
  commentUserId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lcr: Scalars['Float'];
  tasks: Array<Task>;
  telematic?: Maybe<CarTelematic>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};


export type CarTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type CarCount = {
  __typename?: 'CarCount';
  tasks: Scalars['Int'];
};

export type CarCreateManyClientInput = {
  BRAND?: InputMaybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  commentUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lcr?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CarCreateManyClientInputEnvelope = {
  data: Array<CarCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CarCreateManyUserInput = {
  BRAND?: InputMaybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  clientId: Scalars['String'];
  commentUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lcr?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CarCreateManyUserInputEnvelope = {
  data: Array<CarCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CarCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CarCreateWithoutClientInput>>;
  createMany?: InputMaybe<CarCreateManyClientInputEnvelope>;
};

export type CarCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CarCreateWithoutUserInput>>;
  createMany?: InputMaybe<CarCreateManyUserInputEnvelope>;
};

export type CarCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<CarWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CarCreateWithoutTasksInput>;
};

export type CarCreateOrConnectWithoutClientInput = {
  create: CarCreateWithoutClientInput;
  where: CarWhereUniqueInput;
};

export type CarCreateOrConnectWithoutTasksInput = {
  create: CarCreateWithoutTasksInput;
  where: CarWhereUniqueInput;
};

export type CarCreateOrConnectWithoutUserInput = {
  create: CarCreateWithoutUserInput;
  where: CarWhereUniqueInput;
};

export type CarCreateWithoutClientInput = {
  BRAND?: InputMaybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  commentUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lcr?: InputMaybe<Scalars['Float']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCarInput>;
  telematic?: InputMaybe<CarTelematicCreateNestedOneWithoutCarInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCarsInput>;
};

export type CarCreateWithoutTasksInput = {
  BRAND?: InputMaybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  client: ClientCreateNestedOneWithoutCarsInput;
  commentUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lcr?: InputMaybe<Scalars['Float']>;
  telematic?: InputMaybe<CarTelematicCreateNestedOneWithoutCarInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutCarsInput>;
};

export type CarCreateWithoutUserInput = {
  BRAND?: InputMaybe<Scalars['String']>;
  CAR_EQUIPMENT: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  DATE_RELEASE: Scalars['DateTime'];
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  client: ClientCreateNestedOneWithoutCarsInput;
  commentUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  lcr?: InputMaybe<Scalars['Float']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCarInput>;
  telematic?: InputMaybe<CarTelematicCreateNestedOneWithoutCarInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CarListRelationFilter = {
  every?: InputMaybe<CarWhereInput>;
  none?: InputMaybe<CarWhereInput>;
  some?: InputMaybe<CarWhereInput>;
};

/** Модели */
export type CarModel = {
  __typename?: 'CarModel';
  _count?: Maybe<CarModelCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** Модель автомобиля */
  model: Scalars['String'];
  /** Связанные точки бизнеса */
  pointTypes: Array<BrandPointType>;
  updatedAt: Scalars['DateTime'];
};


/** Модели */
export type CarModelPointTypesArgs = {
  cursor?: InputMaybe<BrandPointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BrandPointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BrandPointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BrandPointTypeWhereInput>;
};

export type CarModelCount = {
  __typename?: 'CarModelCount';
  pointTypes: Scalars['Int'];
};

export type CarModelCreateNestedOneWithoutPointTypesInput = {
  connect?: InputMaybe<CarModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarModelCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<CarModelCreateWithoutPointTypesInput>;
};

export type CarModelCreateOrConnectWithoutPointTypesInput = {
  create: CarModelCreateWithoutPointTypesInput;
  where: CarModelWhereUniqueInput;
};

export type CarModelCreateWithoutPointTypesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  model: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CarModelOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  pointTypes?: InputMaybe<BrandPointTypeOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CarModelRelationFilter = {
  is?: InputMaybe<CarModelWhereInput>;
  isNot?: InputMaybe<CarModelWhereInput>;
};

export type CarModelUpdateOneRequiredWithoutPointTypesInput = {
  connect?: InputMaybe<CarModelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarModelCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<CarModelCreateWithoutPointTypesInput>;
  update?: InputMaybe<CarModelUpdateWithoutPointTypesInput>;
  upsert?: InputMaybe<CarModelUpsertWithoutPointTypesInput>;
};

export type CarModelUpdateWithoutPointTypesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  model?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CarModelUpsertWithoutPointTypesInput = {
  create: CarModelCreateWithoutPointTypesInput;
  update: CarModelUpdateWithoutPointTypesInput;
};

export type CarModelWhereInput = {
  AND?: InputMaybe<Array<CarModelWhereInput>>;
  NOT?: InputMaybe<Array<CarModelWhereInput>>;
  OR?: InputMaybe<Array<CarModelWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringFilter>;
  pointTypes?: InputMaybe<BrandPointTypeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CarModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
};

export type CarOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CarOrderByWithRelationInput = {
  BRAND?: InputMaybe<SortOrder>;
  CAR_EQUIPMENT?: InputMaybe<SortOrder>;
  CAR_MODEL?: InputMaybe<SortOrder>;
  CAR_VIN?: InputMaybe<SortOrder>;
  DATE_RELEASE?: InputMaybe<SortOrder>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<SortOrder>;
  SALESMAN?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrder>;
  commentUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lcr?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  telematic?: InputMaybe<CarTelematicOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type CarRelationFilter = {
  is?: InputMaybe<CarWhereInput>;
  isNot?: InputMaybe<CarWhereInput>;
};

export enum CarScalarFieldEnum {
  Brand = 'BRAND',
  CarEquipment = 'CAR_EQUIPMENT',
  CarModel = 'CAR_MODEL',
  CarVin = 'CAR_VIN',
  DateRelease = 'DATE_RELEASE',
  PmVehicleRegistrationPlate = 'PM_VEHICLE_REGISTRATION_PLATE',
  Salesman = 'SALESMAN',
  ClientId = 'clientId',
  CommentUserId = 'commentUserId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Lcr = 'lcr',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type CarScalarWhereInput = {
  AND?: InputMaybe<Array<CarScalarWhereInput>>;
  BRAND?: InputMaybe<StringNullableFilter>;
  CAR_EQUIPMENT?: InputMaybe<StringFilter>;
  CAR_MODEL?: InputMaybe<StringFilter>;
  CAR_VIN?: InputMaybe<StringFilter>;
  DATE_RELEASE?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<CarScalarWhereInput>>;
  OR?: InputMaybe<Array<CarScalarWhereInput>>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<StringNullableFilter>;
  SALESMAN?: InputMaybe<StringNullableFilter>;
  clientId?: InputMaybe<StringFilter>;
  commentUserId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lcr?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

/** Данные телематики для автомобиля */
export type CarTelematic = {
  __typename?: 'CarTelematic';
  /** Автомобиль */
  car: Car;
  carId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dtc?: Maybe<Scalars['String']>;
  mileage?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  voltage?: Maybe<Scalars['Float']>;
};

export type CarTelematicCreateNestedOneWithoutCarInput = {
  connect?: InputMaybe<CarTelematicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarTelematicCreateOrConnectWithoutCarInput>;
  create?: InputMaybe<CarTelematicCreateWithoutCarInput>;
};

export type CarTelematicCreateOrConnectWithoutCarInput = {
  create: CarTelematicCreateWithoutCarInput;
  where: CarTelematicWhereUniqueInput;
};

export type CarTelematicCreateWithoutCarInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dtc?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  voltage?: InputMaybe<Scalars['Float']>;
};

export type CarTelematicOrderByWithRelationInput = {
  car?: InputMaybe<CarOrderByWithRelationInput>;
  carId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dtc?: InputMaybe<SortOrder>;
  mileage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  voltage?: InputMaybe<SortOrder>;
};

export type CarTelematicRelationFilter = {
  is?: InputMaybe<CarTelematicWhereInput>;
  isNot?: InputMaybe<CarTelematicWhereInput>;
};

export type CarTelematicUpdateOneWithoutCarInput = {
  connect?: InputMaybe<CarTelematicWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarTelematicCreateOrConnectWithoutCarInput>;
  create?: InputMaybe<CarTelematicCreateWithoutCarInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CarTelematicUpdateWithoutCarInput>;
  upsert?: InputMaybe<CarTelematicUpsertWithoutCarInput>;
};

export type CarTelematicUpdateWithoutCarInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dtc?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mileage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  voltage?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
};

export type CarTelematicUpsertWithoutCarInput = {
  create: CarTelematicCreateWithoutCarInput;
  update: CarTelematicUpdateWithoutCarInput;
};

export type CarTelematicWhereInput = {
  AND?: InputMaybe<Array<CarTelematicWhereInput>>;
  NOT?: InputMaybe<Array<CarTelematicWhereInput>>;
  OR?: InputMaybe<Array<CarTelematicWhereInput>>;
  car?: InputMaybe<CarRelationFilter>;
  carId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dtc?: InputMaybe<StringNullableFilter>;
  mileage?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  voltage?: InputMaybe<FloatNullableFilter>;
};

export type CarTelematicWhereUniqueInput = {
  carId?: InputMaybe<Scalars['String']>;
};

export type CarUpdateManyMutationInput = {
  BRAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CAR_EQUIPMENT?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  DATE_RELEASE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lcr?: InputMaybe<FloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CarUpdateManyWithWhereWithoutClientInput = {
  data: CarUpdateManyMutationInput;
  where: CarScalarWhereInput;
};

export type CarUpdateManyWithWhereWithoutUserInput = {
  data: CarUpdateManyMutationInput;
  where: CarScalarWhereInput;
};

export type CarUpdateManyWithoutClientInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<CarCreateWithoutClientInput>>;
  createMany?: InputMaybe<CarCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<CarWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarWhereUniqueInput>>;
  set?: InputMaybe<Array<CarWhereUniqueInput>>;
  update?: InputMaybe<Array<CarUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<CarUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<CarUpsertWithWhereUniqueWithoutClientInput>>;
};

export type CarUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CarWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CarCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CarCreateWithoutUserInput>>;
  createMany?: InputMaybe<CarCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CarWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CarScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CarWhereUniqueInput>>;
  set?: InputMaybe<Array<CarWhereUniqueInput>>;
  update?: InputMaybe<Array<CarUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CarUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CarUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CarUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<CarWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CarCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CarCreateWithoutTasksInput>;
  update?: InputMaybe<CarUpdateWithoutTasksInput>;
  upsert?: InputMaybe<CarUpsertWithoutTasksInput>;
};

export type CarUpdateWithWhereUniqueWithoutClientInput = {
  data: CarUpdateWithoutClientInput;
  where: CarWhereUniqueInput;
};

export type CarUpdateWithWhereUniqueWithoutUserInput = {
  data: CarUpdateWithoutUserInput;
  where: CarWhereUniqueInput;
};

export type CarUpdateWithoutClientInput = {
  BRAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CAR_EQUIPMENT?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  DATE_RELEASE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lcr?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCarInput>;
  telematic?: InputMaybe<CarTelematicUpdateOneWithoutCarInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCarsInput>;
};

export type CarUpdateWithoutTasksInput = {
  BRAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CAR_EQUIPMENT?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  DATE_RELEASE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCarsInput>;
  commentUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lcr?: InputMaybe<FloatFieldUpdateOperationsInput>;
  telematic?: InputMaybe<CarTelematicUpdateOneWithoutCarInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutCarsInput>;
};

export type CarUpdateWithoutUserInput = {
  BRAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CAR_EQUIPMENT?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  DATE_RELEASE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutCarsInput>;
  commentUserId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lcr?: InputMaybe<FloatFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCarInput>;
  telematic?: InputMaybe<CarTelematicUpdateOneWithoutCarInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CarUpsertWithWhereUniqueWithoutClientInput = {
  create: CarCreateWithoutClientInput;
  update: CarUpdateWithoutClientInput;
  where: CarWhereUniqueInput;
};

export type CarUpsertWithWhereUniqueWithoutUserInput = {
  create: CarCreateWithoutUserInput;
  update: CarUpdateWithoutUserInput;
  where: CarWhereUniqueInput;
};

export type CarUpsertWithoutTasksInput = {
  create: CarCreateWithoutTasksInput;
  update: CarUpdateWithoutTasksInput;
};

export type CarWhereInput = {
  AND?: InputMaybe<Array<CarWhereInput>>;
  BRAND?: InputMaybe<StringNullableFilter>;
  CAR_EQUIPMENT?: InputMaybe<StringFilter>;
  CAR_MODEL?: InputMaybe<StringFilter>;
  CAR_VIN?: InputMaybe<StringFilter>;
  DATE_RELEASE?: InputMaybe<DateTimeFilter>;
  NOT?: InputMaybe<Array<CarWhereInput>>;
  OR?: InputMaybe<Array<CarWhereInput>>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<StringNullableFilter>;
  SALESMAN?: InputMaybe<StringNullableFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  commentUserId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  lcr?: InputMaybe<FloatFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  telematic?: InputMaybe<CarTelematicRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type CarWhereUniqueInput = {
  CAR_VIN?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CesarResponse = {
  __typename?: 'CesarResponse';
  message?: Maybe<Scalars['String']>;
};

export type Client = {
  __typename?: 'Client';
  CLIENT?: Maybe<Scalars['String']>;
  CLIENT_EMAIL?: Maybe<Scalars['String']>;
  CLIENT_PHONE?: Maybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: Maybe<Scalars['String']>;
  PM_ADDRESS?: Maybe<Scalars['String']>;
  PM_BIRTHDATE?: Maybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: Maybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  PM_EMAIL?: Maybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: Maybe<Scalars['String']>;
  PM_FIO_DRIVER?: Maybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: Maybe<Scalars['String']>;
  PM_PHONE_OWNER?: Maybe<Scalars['String']>;
  _count?: Maybe<ClientCount>;
  cars: Array<Car>;
  clientLinks: Array<ClientLink>;
  commentWhenChangingManager?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  manager: User;
  selfLinks: Array<ClientLink>;
  tasks: Array<Task>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};


export type ClientCarsArgs = {
  cursor?: InputMaybe<CarWhereUniqueInput>;
  distinct?: InputMaybe<Array<CarScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CarOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarWhereInput>;
};


export type ClientClientLinksArgs = {
  cursor?: InputMaybe<ClientLinkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type ClientSelfLinksArgs = {
  cursor?: InputMaybe<ClientLinkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type ClientTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type ClientClientpm_Client_Nameclient_Phoneclient_EmailCompoundUniqueInput = {
  CLIENT: Scalars['String'];
  CLIENT_EMAIL: Scalars['String'];
  CLIENT_PHONE: Scalars['String'];
  PM_CLIENT_NAME: Scalars['String'];
};

export type ClientCount = {
  __typename?: 'ClientCount';
  cars: Scalars['Int'];
  clientLinks: Scalars['Int'];
  selfLinks: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type ClientCreateManyManagerInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientCreateManyManagerInputEnvelope = {
  data: Array<ClientCreateManyManagerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientCreateNestedManyWithoutManagerInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutManagerInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutManagerInput>>;
  createMany?: InputMaybe<ClientCreateManyManagerInputEnvelope>;
};

export type ClientCreateNestedOneWithoutCarsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<ClientCreateWithoutCarsInput>;
};

export type ClientCreateNestedOneWithoutClientLinksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientLinksInput>;
  create?: InputMaybe<ClientCreateWithoutClientLinksInput>;
};

export type ClientCreateNestedOneWithoutSelfLinksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSelfLinksInput>;
  create?: InputMaybe<ClientCreateWithoutSelfLinksInput>;
};

export type ClientCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ClientCreateWithoutTasksInput>;
};

export type ClientCreateOrConnectWithoutCarsInput = {
  create: ClientCreateWithoutCarsInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutClientLinksInput = {
  create: ClientCreateWithoutClientLinksInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutManagerInput = {
  create: ClientCreateWithoutManagerInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutSelfLinksInput = {
  create: ClientCreateWithoutSelfLinksInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateOrConnectWithoutTasksInput = {
  create: ClientCreateWithoutTasksInput;
  where: ClientWhereUniqueInput;
};

export type ClientCreateWithoutCarsInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manager: UserCreateNestedOneWithoutClientsInput;
  selfLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutClientInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientCreateWithoutClientLinksInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  cars?: InputMaybe<CarCreateNestedManyWithoutClientInput>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manager: UserCreateNestedOneWithoutClientsInput;
  selfLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutClientInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientCreateWithoutManagerInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  cars?: InputMaybe<CarCreateNestedManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  selfLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutClientInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientCreateWithoutSelfLinksInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  cars?: InputMaybe<CarCreateNestedManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manager: UserCreateNestedOneWithoutClientsInput;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutClientInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientCreateWithoutTasksInput = {
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: InputMaybe<Scalars['String']>;
  PM_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_BIRTHDATE?: InputMaybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_EMAIL?: InputMaybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: InputMaybe<Scalars['String']>;
  PM_FIO_DRIVER?: InputMaybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<Scalars['String']>;
  PM_PHONE_OWNER?: InputMaybe<Scalars['String']>;
  cars?: InputMaybe<CarCreateNestedManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  manager: UserCreateNestedOneWithoutClientsInput;
  selfLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutSelfInput>;
  uid?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClientLink = {
  __typename?: 'ClientLink';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  link: Client;
  linkId: Scalars['String'];
  linkType: ClientLinkType;
  linkTypeId: Scalars['String'];
  self: Client;
  selfId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ClientLinkCreateManyLinkInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkTypeId: Scalars['String'];
  selfId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkCreateManyLinkInputEnvelope = {
  data: Array<ClientLinkCreateManyLinkInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientLinkCreateManyLinkTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkId: Scalars['String'];
  selfId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkCreateManyLinkTypeInputEnvelope = {
  data: Array<ClientLinkCreateManyLinkTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientLinkCreateManySelfInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkId: Scalars['String'];
  linkTypeId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkCreateManySelfInputEnvelope = {
  data: Array<ClientLinkCreateManySelfInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClientLinkCreateNestedManyWithoutLinkInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutLinkInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutLinkInput>>;
  createMany?: InputMaybe<ClientLinkCreateManyLinkInputEnvelope>;
};

export type ClientLinkCreateNestedManyWithoutLinkTypeInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutLinkTypeInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutLinkTypeInput>>;
  createMany?: InputMaybe<ClientLinkCreateManyLinkTypeInputEnvelope>;
};

export type ClientLinkCreateNestedManyWithoutSelfInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutSelfInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutSelfInput>>;
  createMany?: InputMaybe<ClientLinkCreateManySelfInputEnvelope>;
};

export type ClientLinkCreateOrConnectWithoutLinkInput = {
  create: ClientLinkCreateWithoutLinkInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkCreateOrConnectWithoutLinkTypeInput = {
  create: ClientLinkCreateWithoutLinkTypeInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkCreateOrConnectWithoutSelfInput = {
  create: ClientLinkCreateWithoutSelfInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkCreateWithoutLinkInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkType: ClientLinkTypeCreateNestedOneWithoutClientLinksInput;
  self: ClientCreateNestedOneWithoutSelfLinksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkCreateWithoutLinkTypeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  link: ClientCreateNestedOneWithoutClientLinksInput;
  self: ClientCreateNestedOneWithoutSelfLinksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkCreateWithoutSelfInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  link: ClientCreateNestedOneWithoutClientLinksInput;
  linkType: ClientLinkTypeCreateNestedOneWithoutClientLinksInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type ClientLinkHistory = {
  __typename?: 'ClientLinkHistory';
  clientLinkId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  linkId: Scalars['String'];
  linkTypeId: Scalars['String'];
  selfId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ClientLinkHistoryOrderByWithRelationInput = {
  clientLinkId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  linkId?: InputMaybe<SortOrder>;
  linkTypeId?: InputMaybe<SortOrder>;
  selfId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClientLinkHistoryScalarFieldEnum {
  ClientLinkId = 'clientLinkId',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsDeleted = 'isDeleted',
  LinkId = 'linkId',
  LinkTypeId = 'linkTypeId',
  SelfId = 'selfId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ClientLinkHistoryWhereInput = {
  AND?: InputMaybe<Array<ClientLinkHistoryWhereInput>>;
  NOT?: InputMaybe<Array<ClientLinkHistoryWhereInput>>;
  OR?: InputMaybe<Array<ClientLinkHistoryWhereInput>>;
  clientLinkId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  linkId?: InputMaybe<StringFilter>;
  linkTypeId?: InputMaybe<StringFilter>;
  selfId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientLinkHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ClientLinkListRelationFilter = {
  every?: InputMaybe<ClientLinkWhereInput>;
  none?: InputMaybe<ClientLinkWhereInput>;
  some?: InputMaybe<ClientLinkWhereInput>;
};

export type ClientLinkOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientLinkOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  link?: InputMaybe<ClientOrderByWithRelationInput>;
  linkId?: InputMaybe<SortOrder>;
  linkType?: InputMaybe<ClientLinkTypeOrderByWithRelationInput>;
  linkTypeId?: InputMaybe<SortOrder>;
  self?: InputMaybe<ClientOrderByWithRelationInput>;
  selfId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export enum ClientLinkScalarFieldEnum {
  CreatedAt = 'createdAt',
  Id = 'id',
  LinkId = 'linkId',
  LinkTypeId = 'linkTypeId',
  SelfId = 'selfId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ClientLinkScalarWhereInput = {
  AND?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  linkId?: InputMaybe<StringFilter>;
  linkTypeId?: InputMaybe<StringFilter>;
  selfId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientLinkSelfIdLinkIdCompoundUniqueInput = {
  linkId: Scalars['String'];
  selfId: Scalars['String'];
};

export type ClientLinkType = {
  __typename?: 'ClientLinkType';
  _count?: Maybe<ClientLinkTypeCount>;
  antonym?: Maybe<ClientLinkType>;
  /** Antonym ClientLinkType ID. Use self link for symmetric link. */
  antonymId?: Maybe<Scalars['String']>;
  clientLinks: Array<ClientLink>;
  id: Scalars['String'];
  limit: Scalars['Int'];
  name: Scalars['String'];
  self?: Maybe<ClientLinkType>;
  type: Scalars['String'];
};


export type ClientLinkTypeClientLinksArgs = {
  cursor?: InputMaybe<ClientLinkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkWhereInput>;
};

export type ClientLinkTypeCount = {
  __typename?: 'ClientLinkTypeCount';
  clientLinks: Scalars['Int'];
};

export type ClientLinkTypeCreateNestedOneWithoutAntonymInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutAntonymInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutAntonymInput>;
};

export type ClientLinkTypeCreateNestedOneWithoutClientLinksInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutClientLinksInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutClientLinksInput>;
};

export type ClientLinkTypeCreateNestedOneWithoutSelfInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutSelfInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutSelfInput>;
};

export type ClientLinkTypeCreateOrConnectWithoutAntonymInput = {
  create: ClientLinkTypeCreateWithoutAntonymInput;
  where: ClientLinkTypeWhereUniqueInput;
};

export type ClientLinkTypeCreateOrConnectWithoutClientLinksInput = {
  create: ClientLinkTypeCreateWithoutClientLinksInput;
  where: ClientLinkTypeWhereUniqueInput;
};

export type ClientLinkTypeCreateOrConnectWithoutSelfInput = {
  create: ClientLinkTypeCreateWithoutSelfInput;
  where: ClientLinkTypeWhereUniqueInput;
};

export type ClientLinkTypeCreateWithoutAntonymInput = {
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  self?: InputMaybe<ClientLinkTypeCreateNestedOneWithoutAntonymInput>;
  type: Scalars['String'];
};

export type ClientLinkTypeCreateWithoutClientLinksInput = {
  antonym?: InputMaybe<ClientLinkTypeCreateNestedOneWithoutSelfInput>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  self?: InputMaybe<ClientLinkTypeCreateNestedOneWithoutAntonymInput>;
  type: Scalars['String'];
};

export type ClientLinkTypeCreateWithoutSelfInput = {
  antonym?: InputMaybe<ClientLinkTypeCreateNestedOneWithoutSelfInput>;
  clientLinks?: InputMaybe<ClientLinkCreateNestedManyWithoutLinkTypeInput>;
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ClientLinkTypeOrderByWithRelationInput = {
  antonym?: InputMaybe<ClientLinkTypeOrderByWithRelationInput>;
  antonymId?: InputMaybe<SortOrder>;
  clientLinks?: InputMaybe<ClientLinkOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  self?: InputMaybe<ClientLinkTypeOrderByWithRelationInput>;
  type?: InputMaybe<SortOrder>;
};

export type ClientLinkTypeRelationFilter = {
  is?: InputMaybe<ClientLinkTypeWhereInput>;
  isNot?: InputMaybe<ClientLinkTypeWhereInput>;
};

export enum ClientLinkTypeScalarFieldEnum {
  AntonymId = 'antonymId',
  Id = 'id',
  Limit = 'limit',
  Name = 'name',
  Type = 'type'
}

export type ClientLinkTypeUpdateOneRequiredWithoutClientLinksInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutClientLinksInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutClientLinksInput>;
  update?: InputMaybe<ClientLinkTypeUpdateWithoutClientLinksInput>;
  upsert?: InputMaybe<ClientLinkTypeUpsertWithoutClientLinksInput>;
};

export type ClientLinkTypeUpdateOneWithoutAntonymInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutAntonymInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutAntonymInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ClientLinkTypeUpdateWithoutAntonymInput>;
  upsert?: InputMaybe<ClientLinkTypeUpsertWithoutAntonymInput>;
};

export type ClientLinkTypeUpdateOneWithoutSelfInput = {
  connect?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientLinkTypeCreateOrConnectWithoutSelfInput>;
  create?: InputMaybe<ClientLinkTypeCreateWithoutSelfInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ClientLinkTypeUpdateWithoutSelfInput>;
  upsert?: InputMaybe<ClientLinkTypeUpsertWithoutSelfInput>;
};

export type ClientLinkTypeUpdateWithoutAntonymInput = {
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkTypeInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  self?: InputMaybe<ClientLinkTypeUpdateOneWithoutAntonymInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkTypeUpdateWithoutClientLinksInput = {
  antonym?: InputMaybe<ClientLinkTypeUpdateOneWithoutSelfInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  self?: InputMaybe<ClientLinkTypeUpdateOneWithoutAntonymInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkTypeUpdateWithoutSelfInput = {
  antonym?: InputMaybe<ClientLinkTypeUpdateOneWithoutSelfInput>;
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkTypeInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkTypeUpsertWithoutAntonymInput = {
  create: ClientLinkTypeCreateWithoutAntonymInput;
  update: ClientLinkTypeUpdateWithoutAntonymInput;
};

export type ClientLinkTypeUpsertWithoutClientLinksInput = {
  create: ClientLinkTypeCreateWithoutClientLinksInput;
  update: ClientLinkTypeUpdateWithoutClientLinksInput;
};

export type ClientLinkTypeUpsertWithoutSelfInput = {
  create: ClientLinkTypeCreateWithoutSelfInput;
  update: ClientLinkTypeUpdateWithoutSelfInput;
};

export type ClientLinkTypeWhereInput = {
  AND?: InputMaybe<Array<ClientLinkTypeWhereInput>>;
  NOT?: InputMaybe<Array<ClientLinkTypeWhereInput>>;
  OR?: InputMaybe<Array<ClientLinkTypeWhereInput>>;
  antonym?: InputMaybe<ClientLinkTypeRelationFilter>;
  antonymId?: InputMaybe<StringNullableFilter>;
  clientLinks?: InputMaybe<ClientLinkListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  limit?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  self?: InputMaybe<ClientLinkTypeRelationFilter>;
  type?: InputMaybe<StringFilter>;
};

export type ClientLinkTypeWhereUniqueInput = {
  antonymId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type ClientLinkUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  link?: InputMaybe<ClientUpdateOneRequiredWithoutClientLinksInput>;
  linkType?: InputMaybe<ClientLinkTypeUpdateOneRequiredWithoutClientLinksInput>;
  self?: InputMaybe<ClientUpdateOneRequiredWithoutSelfLinksInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkUpdateManyWithWhereWithoutLinkInput = {
  data: ClientLinkUpdateManyMutationInput;
  where: ClientLinkScalarWhereInput;
};

export type ClientLinkUpdateManyWithWhereWithoutLinkTypeInput = {
  data: ClientLinkUpdateManyMutationInput;
  where: ClientLinkScalarWhereInput;
};

export type ClientLinkUpdateManyWithWhereWithoutSelfInput = {
  data: ClientLinkUpdateManyMutationInput;
  where: ClientLinkScalarWhereInput;
};

export type ClientLinkUpdateManyWithoutLinkInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutLinkInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutLinkInput>>;
  createMany?: InputMaybe<ClientLinkCreateManyLinkInputEnvelope>;
  delete?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientLinkUpdateWithWhereUniqueWithoutLinkInput>>;
  updateMany?: InputMaybe<Array<ClientLinkUpdateManyWithWhereWithoutLinkInput>>;
  upsert?: InputMaybe<Array<ClientLinkUpsertWithWhereUniqueWithoutLinkInput>>;
};

export type ClientLinkUpdateManyWithoutLinkTypeInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutLinkTypeInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutLinkTypeInput>>;
  createMany?: InputMaybe<ClientLinkCreateManyLinkTypeInputEnvelope>;
  delete?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientLinkUpdateWithWhereUniqueWithoutLinkTypeInput>>;
  updateMany?: InputMaybe<Array<ClientLinkUpdateManyWithWhereWithoutLinkTypeInput>>;
  upsert?: InputMaybe<Array<ClientLinkUpsertWithWhereUniqueWithoutLinkTypeInput>>;
};

export type ClientLinkUpdateManyWithoutSelfInput = {
  connect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientLinkCreateOrConnectWithoutSelfInput>>;
  create?: InputMaybe<Array<ClientLinkCreateWithoutSelfInput>>;
  createMany?: InputMaybe<ClientLinkCreateManySelfInputEnvelope>;
  delete?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientLinkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientLinkWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientLinkUpdateWithWhereUniqueWithoutSelfInput>>;
  updateMany?: InputMaybe<Array<ClientLinkUpdateManyWithWhereWithoutSelfInput>>;
  upsert?: InputMaybe<Array<ClientLinkUpsertWithWhereUniqueWithoutSelfInput>>;
};

export type ClientLinkUpdateWithWhereUniqueWithoutLinkInput = {
  data: ClientLinkUpdateWithoutLinkInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkUpdateWithWhereUniqueWithoutLinkTypeInput = {
  data: ClientLinkUpdateWithoutLinkTypeInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkUpdateWithWhereUniqueWithoutSelfInput = {
  data: ClientLinkUpdateWithoutSelfInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkUpdateWithoutLinkInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkType?: InputMaybe<ClientLinkTypeUpdateOneRequiredWithoutClientLinksInput>;
  self?: InputMaybe<ClientUpdateOneRequiredWithoutSelfLinksInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkUpdateWithoutLinkTypeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  link?: InputMaybe<ClientUpdateOneRequiredWithoutClientLinksInput>;
  self?: InputMaybe<ClientUpdateOneRequiredWithoutSelfLinksInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkUpdateWithoutSelfInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  link?: InputMaybe<ClientUpdateOneRequiredWithoutClientLinksInput>;
  linkType?: InputMaybe<ClientLinkTypeUpdateOneRequiredWithoutClientLinksInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ClientLinkUpsertWithWhereUniqueWithoutLinkInput = {
  create: ClientLinkCreateWithoutLinkInput;
  update: ClientLinkUpdateWithoutLinkInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkUpsertWithWhereUniqueWithoutLinkTypeInput = {
  create: ClientLinkCreateWithoutLinkTypeInput;
  update: ClientLinkUpdateWithoutLinkTypeInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkUpsertWithWhereUniqueWithoutSelfInput = {
  create: ClientLinkCreateWithoutSelfInput;
  update: ClientLinkUpdateWithoutSelfInput;
  where: ClientLinkWhereUniqueInput;
};

export type ClientLinkWhereInput = {
  AND?: InputMaybe<Array<ClientLinkWhereInput>>;
  NOT?: InputMaybe<Array<ClientLinkWhereInput>>;
  OR?: InputMaybe<Array<ClientLinkWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  link?: InputMaybe<ClientRelationFilter>;
  linkId?: InputMaybe<StringFilter>;
  linkType?: InputMaybe<ClientLinkTypeRelationFilter>;
  linkTypeId?: InputMaybe<StringFilter>;
  self?: InputMaybe<ClientRelationFilter>;
  selfId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  selfId_linkId?: InputMaybe<ClientLinkSelfIdLinkIdCompoundUniqueInput>;
};

export type ClientList = {
  __typename?: 'ClientList';
  CLIENT?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lcr: Scalars['Float'];
  managers: Array<User>;
  userId: Scalars['String'];
};

export type ClientListRelationFilter = {
  every?: InputMaybe<ClientWhereInput>;
  none?: InputMaybe<ClientWhereInput>;
  some?: InputMaybe<ClientWhereInput>;
};

export type ClientOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ClientOrderByWithRelationInput = {
  CLIENT?: InputMaybe<SortOrder>;
  CLIENT_EMAIL?: InputMaybe<SortOrder>;
  CLIENT_PHONE?: InputMaybe<SortOrder>;
  CLIENT_TYPE?: InputMaybe<SortOrder>;
  FAMILY_ID?: InputMaybe<SortOrder>;
  PM_ADDRESS?: InputMaybe<SortOrder>;
  PM_BIRTHDATE?: InputMaybe<SortOrder>;
  PM_CLIENT_ADDRESS?: InputMaybe<SortOrder>;
  PM_CLIENT_ADDRESS2?: InputMaybe<SortOrder>;
  PM_CLIENT_NAME?: InputMaybe<SortOrder>;
  PM_EMAIL?: InputMaybe<SortOrder>;
  PM_FAMILY_CLUSTER?: InputMaybe<SortOrder>;
  PM_FIO_DRIVER?: InputMaybe<SortOrder>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<SortOrder>;
  PM_PHONE_OWNER?: InputMaybe<SortOrder>;
  cars?: InputMaybe<CarOrderByRelationAggregateInput>;
  clientLinks?: InputMaybe<ClientLinkOrderByRelationAggregateInput>;
  commentWhenChangingManager?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  manager?: InputMaybe<UserOrderByWithRelationInput>;
  selfLinks?: InputMaybe<ClientLinkOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  uid?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ClientRelationFilter = {
  is?: InputMaybe<ClientWhereInput>;
  isNot?: InputMaybe<ClientWhereInput>;
};

export enum ClientScalarFieldEnum {
  Client = 'CLIENT',
  ClientEmail = 'CLIENT_EMAIL',
  ClientPhone = 'CLIENT_PHONE',
  ClientType = 'CLIENT_TYPE',
  FamilyId = 'FAMILY_ID',
  PmAddress = 'PM_ADDRESS',
  PmBirthdate = 'PM_BIRTHDATE',
  PmClientAddress = 'PM_CLIENT_ADDRESS',
  PmClientAddress2 = 'PM_CLIENT_ADDRESS2',
  PmClientName = 'PM_CLIENT_NAME',
  PmEmail = 'PM_EMAIL',
  PmFamilyCluster = 'PM_FAMILY_CLUSTER',
  PmFioDriver = 'PM_FIO_DRIVER',
  PmFioWifesHusband = 'PM_FIO_WIFES_HUSBAND',
  PmPhoneOwner = 'PM_PHONE_OWNER',
  CommentWhenChangingManager = 'commentWhenChangingManager',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Uid = 'uid',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type ClientScalarWhereInput = {
  AND?: InputMaybe<Array<ClientScalarWhereInput>>;
  CLIENT?: InputMaybe<StringNullableFilter>;
  CLIENT_EMAIL?: InputMaybe<StringNullableFilter>;
  CLIENT_PHONE?: InputMaybe<StringNullableFilter>;
  CLIENT_TYPE?: InputMaybe<StringFilter>;
  FAMILY_ID?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<ClientScalarWhereInput>>;
  OR?: InputMaybe<Array<ClientScalarWhereInput>>;
  PM_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_BIRTHDATE?: InputMaybe<DateTimeNullableFilter>;
  PM_CLIENT_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_ADDRESS2?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  PM_EMAIL?: InputMaybe<StringNullableFilter>;
  PM_FAMILY_CLUSTER?: InputMaybe<StringNullableFilter>;
  PM_FIO_DRIVER?: InputMaybe<StringNullableFilter>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<StringNullableFilter>;
  PM_PHONE_OWNER?: InputMaybe<StringNullableFilter>;
  commentWhenChangingManager?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientUpdateManyMutationInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpdateManyWithWhereWithoutManagerInput = {
  data: ClientUpdateManyMutationInput;
  where: ClientScalarWhereInput;
};

export type ClientUpdateManyWithoutManagerInput = {
  connect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClientCreateOrConnectWithoutManagerInput>>;
  create?: InputMaybe<Array<ClientCreateWithoutManagerInput>>;
  createMany?: InputMaybe<ClientCreateManyManagerInputEnvelope>;
  delete?: InputMaybe<Array<ClientWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClientScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClientWhereUniqueInput>>;
  set?: InputMaybe<Array<ClientWhereUniqueInput>>;
  update?: InputMaybe<Array<ClientUpdateWithWhereUniqueWithoutManagerInput>>;
  updateMany?: InputMaybe<Array<ClientUpdateManyWithWhereWithoutManagerInput>>;
  upsert?: InputMaybe<Array<ClientUpsertWithWhereUniqueWithoutManagerInput>>;
};

export type ClientUpdateOneRequiredWithoutCarsInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<ClientCreateWithoutCarsInput>;
  update?: InputMaybe<ClientUpdateWithoutCarsInput>;
  upsert?: InputMaybe<ClientUpsertWithoutCarsInput>;
};

export type ClientUpdateOneRequiredWithoutClientLinksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutClientLinksInput>;
  create?: InputMaybe<ClientCreateWithoutClientLinksInput>;
  update?: InputMaybe<ClientUpdateWithoutClientLinksInput>;
  upsert?: InputMaybe<ClientUpsertWithoutClientLinksInput>;
};

export type ClientUpdateOneRequiredWithoutSelfLinksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutSelfLinksInput>;
  create?: InputMaybe<ClientCreateWithoutSelfLinksInput>;
  update?: InputMaybe<ClientUpdateWithoutSelfLinksInput>;
  upsert?: InputMaybe<ClientUpsertWithoutSelfLinksInput>;
};

export type ClientUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<ClientWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClientCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<ClientCreateWithoutTasksInput>;
  update?: InputMaybe<ClientUpdateWithoutTasksInput>;
  upsert?: InputMaybe<ClientUpsertWithoutTasksInput>;
};

export type ClientUpdateWithWhereUniqueWithoutManagerInput = {
  data: ClientUpdateWithoutManagerInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpdateWithoutCarsInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutClientsInput>;
  selfLinks?: InputMaybe<ClientLinkUpdateManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutClientInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutClientLinksInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutClientInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutClientsInput>;
  selfLinks?: InputMaybe<ClientLinkUpdateManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutClientInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutManagerInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  selfLinks?: InputMaybe<ClientLinkUpdateManyWithoutSelfInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutClientInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutSelfLinksInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutClientsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutClientInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpdateWithoutTasksInput = {
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  FAMILY_ID?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_BIRTHDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FAMILY_CLUSTER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_DRIVER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE_OWNER?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutClientInput>;
  clientLinks?: InputMaybe<ClientLinkUpdateManyWithoutLinkInput>;
  commentWhenChangingManager?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutClientsInput>;
  selfLinks?: InputMaybe<ClientLinkUpdateManyWithoutSelfInput>;
  uid?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClientUpsertWithWhereUniqueWithoutManagerInput = {
  create: ClientCreateWithoutManagerInput;
  update: ClientUpdateWithoutManagerInput;
  where: ClientWhereUniqueInput;
};

export type ClientUpsertWithoutCarsInput = {
  create: ClientCreateWithoutCarsInput;
  update: ClientUpdateWithoutCarsInput;
};

export type ClientUpsertWithoutClientLinksInput = {
  create: ClientCreateWithoutClientLinksInput;
  update: ClientUpdateWithoutClientLinksInput;
};

export type ClientUpsertWithoutSelfLinksInput = {
  create: ClientCreateWithoutSelfLinksInput;
  update: ClientUpdateWithoutSelfLinksInput;
};

export type ClientUpsertWithoutTasksInput = {
  create: ClientCreateWithoutTasksInput;
  update: ClientUpdateWithoutTasksInput;
};

export type ClientWhereInput = {
  AND?: InputMaybe<Array<ClientWhereInput>>;
  CLIENT?: InputMaybe<StringNullableFilter>;
  CLIENT_EMAIL?: InputMaybe<StringNullableFilter>;
  CLIENT_PHONE?: InputMaybe<StringNullableFilter>;
  CLIENT_TYPE?: InputMaybe<StringFilter>;
  FAMILY_ID?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<ClientWhereInput>>;
  OR?: InputMaybe<Array<ClientWhereInput>>;
  PM_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_BIRTHDATE?: InputMaybe<DateTimeNullableFilter>;
  PM_CLIENT_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_ADDRESS2?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  PM_EMAIL?: InputMaybe<StringNullableFilter>;
  PM_FAMILY_CLUSTER?: InputMaybe<StringNullableFilter>;
  PM_FIO_DRIVER?: InputMaybe<StringNullableFilter>;
  PM_FIO_WIFES_HUSBAND?: InputMaybe<StringNullableFilter>;
  PM_PHONE_OWNER?: InputMaybe<StringNullableFilter>;
  cars?: InputMaybe<CarListRelationFilter>;
  clientLinks?: InputMaybe<ClientLinkListRelationFilter>;
  commentWhenChangingManager?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  manager?: InputMaybe<UserRelationFilter>;
  selfLinks?: InputMaybe<ClientLinkListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  uid?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ClientWhereUniqueInput = {
  CLIENT_PM_CLIENT_NAME_CLIENT_PHONE_CLIENT_EMAIL?: InputMaybe<ClientClientpm_Client_Nameclient_Phoneclient_EmailCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
  uid?: InputMaybe<Scalars['String']>;
};

export type CommentTemplates = {
  __typename?: 'CommentTemplates';
  CATEGORY: Scalars['String'];
  CODE: Scalars['String'];
  TEXT: Scalars['String'];
  _count?: Maybe<CommentTemplatesCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  pointTypes: Array<PointTypeCommentTemplate>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type CommentTemplatesPointTypesArgs = {
  cursor?: InputMaybe<PointTypeCommentTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointTypeCommentTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointTypeCommentTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PointTypeCommentTemplateWhereInput>;
};


export type CommentTemplatesTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type CommentTemplatesCount = {
  __typename?: 'CommentTemplatesCount';
  pointTypes: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type CommentTemplatesCreateNestedOneWithoutPointTypesInput = {
  connect?: InputMaybe<CommentTemplatesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentTemplatesCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<CommentTemplatesCreateWithoutPointTypesInput>;
};

export type CommentTemplatesCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<CommentTemplatesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentTemplatesCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CommentTemplatesCreateWithoutTasksInput>;
};

export type CommentTemplatesCreateOrConnectWithoutPointTypesInput = {
  create: CommentTemplatesCreateWithoutPointTypesInput;
  where: CommentTemplatesWhereUniqueInput;
};

export type CommentTemplatesCreateOrConnectWithoutTasksInput = {
  create: CommentTemplatesCreateWithoutTasksInput;
  where: CommentTemplatesWhereUniqueInput;
};

export type CommentTemplatesCreateWithoutPointTypesInput = {
  CATEGORY: Scalars['String'];
  CODE: Scalars['String'];
  TEXT: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutCommentTemplateInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommentTemplatesCreateWithoutTasksInput = {
  CATEGORY: Scalars['String'];
  CODE: Scalars['String'];
  TEXT: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  pointTypes?: InputMaybe<PointTypeCommentTemplateCreateNestedManyWithoutCommentTemplatesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommentTemplatesOrderByWithRelationInput = {
  CATEGORY?: InputMaybe<SortOrder>;
  CODE?: InputMaybe<SortOrder>;
  TEXT?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  pointTypes?: InputMaybe<PointTypeCommentTemplateOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CommentTemplatesRelationFilter = {
  is?: InputMaybe<CommentTemplatesWhereInput>;
  isNot?: InputMaybe<CommentTemplatesWhereInput>;
};

export enum CommentTemplatesScalarFieldEnum {
  Category = 'CATEGORY',
  Code = 'CODE',
  Text = 'TEXT',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type CommentTemplatesUpdateOneRequiredWithoutPointTypesInput = {
  connect?: InputMaybe<CommentTemplatesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentTemplatesCreateOrConnectWithoutPointTypesInput>;
  create?: InputMaybe<CommentTemplatesCreateWithoutPointTypesInput>;
  update?: InputMaybe<CommentTemplatesUpdateWithoutPointTypesInput>;
  upsert?: InputMaybe<CommentTemplatesUpsertWithoutPointTypesInput>;
};

export type CommentTemplatesUpdateOneWithoutTasksInput = {
  connect?: InputMaybe<CommentTemplatesWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommentTemplatesCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CommentTemplatesCreateWithoutTasksInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommentTemplatesUpdateWithoutTasksInput>;
  upsert?: InputMaybe<CommentTemplatesUpsertWithoutTasksInput>;
};

export type CommentTemplatesUpdateWithoutPointTypesInput = {
  CATEGORY?: InputMaybe<StringFieldUpdateOperationsInput>;
  CODE?: InputMaybe<StringFieldUpdateOperationsInput>;
  TEXT?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutCommentTemplateInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentTemplatesUpdateWithoutTasksInput = {
  CATEGORY?: InputMaybe<StringFieldUpdateOperationsInput>;
  CODE?: InputMaybe<StringFieldUpdateOperationsInput>;
  TEXT?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  pointTypes?: InputMaybe<PointTypeCommentTemplateUpdateManyWithoutCommentTemplatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentTemplatesUpsertWithoutPointTypesInput = {
  create: CommentTemplatesCreateWithoutPointTypesInput;
  update: CommentTemplatesUpdateWithoutPointTypesInput;
};

export type CommentTemplatesUpsertWithoutTasksInput = {
  create: CommentTemplatesCreateWithoutTasksInput;
  update: CommentTemplatesUpdateWithoutTasksInput;
};

export type CommentTemplatesWhereInput = {
  AND?: InputMaybe<Array<CommentTemplatesWhereInput>>;
  CATEGORY?: InputMaybe<StringFilter>;
  CODE?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<CommentTemplatesWhereInput>>;
  OR?: InputMaybe<Array<CommentTemplatesWhereInput>>;
  TEXT?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  pointTypes?: InputMaybe<PointTypeCommentTemplateListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommentTemplatesWhereUniqueInput = {
  TEXT?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  ORDER_COMPANY: Scalars['String'];
  _count?: Maybe<CompanyCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type CompanyTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type CompanyCount = {
  __typename?: 'CompanyCount';
  tasks: Scalars['Int'];
};

export type CompanyCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CompanyCreateWithoutTasksInput>;
};

export type CompanyCreateOrConnectWithoutTasksInput = {
  create: CompanyCreateWithoutTasksInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutTasksInput = {
  ORDER_COMPANY: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyOrderByWithRelationInput = {
  ORDER_COMPANY?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<CompanyCreateWithoutTasksInput>;
  update?: InputMaybe<CompanyUpdateWithoutTasksInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutTasksInput>;
};

export type CompanyUpdateWithoutTasksInput = {
  ORDER_COMPANY?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CompanyUpsertWithoutTasksInput = {
  create: CompanyCreateWithoutTasksInput;
  update: CompanyUpdateWithoutTasksInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  ORDER_COMPANY?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CompanyWhereUniqueInput = {
  ORDER_COMPANY?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DeviceInfoResponse = {
  __typename?: 'DeviceInfoResponse';
  message?: Maybe<Scalars['String']>;
};

export type DzrtResponse = {
  __typename?: 'DzrtResponse';
  message?: Maybe<Scalars['String']>;
};

export type EnumPointNameFieldUpdateOperationsInput = {
  set?: InputMaybe<PointName>;
};

export type EnumPointNameFilter = {
  equals?: InputMaybe<PointName>;
  in?: InputMaybe<Array<PointName>>;
  not?: InputMaybe<NestedEnumPointNameFilter>;
  notIn?: InputMaybe<Array<PointName>>;
};

export type EnumRoleNameFieldUpdateOperationsInput = {
  set?: InputMaybe<RoleName>;
};

export type EnumRoleNameFilter = {
  equals?: InputMaybe<RoleName>;
  in?: InputMaybe<Array<RoleName>>;
  not?: InputMaybe<NestedEnumRoleNameFilter>;
  notIn?: InputMaybe<Array<RoleName>>;
};

export type ExportInput = {
  exportDir?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fromDate?: InputMaybe<Scalars['DateTime']>;
  toDate?: InputMaybe<Scalars['DateTime']>;
  zip?: InputMaybe<Scalars['Boolean']>;
};

export type ExportResponse = {
  __typename?: 'ExportResponse';
  message?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type ImportMetricResponse = {
  __typename?: 'ImportMetricResponse';
  message?: Maybe<Scalars['String']>;
};

/** Создаваемые точки бизнеса */
export type ImportPointType = {
  __typename?: 'ImportPointType';
  /** Условия */
  condition?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: PointName;
  /** Точка бизнеса */
  pointType: PointType;
  /** ID точки бизнеса */
  pointTypeId: Scalars['String'];
  /** Порядок проверки условий */
  priority?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type ImportPointTypeCreateManyPointTypeInput = {
  condition?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: PointName;
  priority?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImportPointTypeCreateManyPointTypeInputEnvelope = {
  data: Array<ImportPointTypeCreateManyPointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ImportPointTypeCreateNestedManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<ImportPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImportPointTypeCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<ImportPointTypeCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<ImportPointTypeCreateManyPointTypeInputEnvelope>;
};

export type ImportPointTypeCreateOrConnectWithoutPointTypeInput = {
  create: ImportPointTypeCreateWithoutPointTypeInput;
  where: ImportPointTypeWhereUniqueInput;
};

export type ImportPointTypeCreateWithoutPointTypeInput = {
  condition?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: PointName;
  priority?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ImportPointTypeListRelationFilter = {
  every?: InputMaybe<ImportPointTypeWhereInput>;
  none?: InputMaybe<ImportPointTypeWhereInput>;
  some?: InputMaybe<ImportPointTypeWhereInput>;
};

export type ImportPointTypeNamePointTypeIdCompoundUniqueInput = {
  name: PointName;
  pointTypeId: Scalars['String'];
};

export type ImportPointTypeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ImportPointTypeOrderByWithRelationInput = {
  condition?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  pointType?: InputMaybe<PointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrder>;
  priority?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ImportPointTypeScalarFieldEnum {
  Condition = 'condition',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  PointTypeId = 'pointTypeId',
  Priority = 'priority',
  UpdatedAt = 'updatedAt'
}

export type ImportPointTypeScalarWhereInput = {
  AND?: InputMaybe<Array<ImportPointTypeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ImportPointTypeScalarWhereInput>>;
  OR?: InputMaybe<Array<ImportPointTypeScalarWhereInput>>;
  condition?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumPointNameFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ImportPointTypeUpdateManyMutationInput = {
  condition?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumPointNameFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ImportPointTypeUpdateManyWithWhereWithoutPointTypeInput = {
  data: ImportPointTypeUpdateManyMutationInput;
  where: ImportPointTypeScalarWhereInput;
};

export type ImportPointTypeUpdateManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<ImportPointTypeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ImportPointTypeCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<ImportPointTypeCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<ImportPointTypeCreateManyPointTypeInputEnvelope>;
  delete?: InputMaybe<Array<ImportPointTypeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ImportPointTypeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ImportPointTypeWhereUniqueInput>>;
  set?: InputMaybe<Array<ImportPointTypeWhereUniqueInput>>;
  update?: InputMaybe<Array<ImportPointTypeUpdateWithWhereUniqueWithoutPointTypeInput>>;
  updateMany?: InputMaybe<Array<ImportPointTypeUpdateManyWithWhereWithoutPointTypeInput>>;
  upsert?: InputMaybe<Array<ImportPointTypeUpsertWithWhereUniqueWithoutPointTypeInput>>;
};

export type ImportPointTypeUpdateWithWhereUniqueWithoutPointTypeInput = {
  data: ImportPointTypeUpdateWithoutPointTypeInput;
  where: ImportPointTypeWhereUniqueInput;
};

export type ImportPointTypeUpdateWithoutPointTypeInput = {
  condition?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumPointNameFieldUpdateOperationsInput>;
  priority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ImportPointTypeUpsertWithWhereUniqueWithoutPointTypeInput = {
  create: ImportPointTypeCreateWithoutPointTypeInput;
  update: ImportPointTypeUpdateWithoutPointTypeInput;
  where: ImportPointTypeWhereUniqueInput;
};

export type ImportPointTypeWhereInput = {
  AND?: InputMaybe<Array<ImportPointTypeWhereInput>>;
  NOT?: InputMaybe<Array<ImportPointTypeWhereInput>>;
  OR?: InputMaybe<Array<ImportPointTypeWhereInput>>;
  condition?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumPointNameFilter>;
  pointType?: InputMaybe<PointTypeRelationFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  priority?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ImportPointTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name_pointTypeId?: InputMaybe<ImportPointTypeNamePointTypeIdCompoundUniqueInput>;
};

export type ImportResponse = {
  __typename?: 'ImportResponse';
  message?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type LcrClient = {
  __typename?: 'LcrClient';
  CLIENT?: Maybe<Scalars['String']>;
  CLIENT_EMAIL?: Maybe<Scalars['String']>;
  CLIENT_PHONE?: Maybe<Scalars['String']>;
  CLIENT_TYPE: Scalars['String'];
  FAMILY_ID?: Maybe<Scalars['String']>;
  PM_ADDRESS?: Maybe<Scalars['String']>;
  PM_BIRTHDATE?: Maybe<Scalars['DateTime']>;
  PM_CLIENT_ADDRESS?: Maybe<Scalars['String']>;
  PM_CLIENT_ADDRESS2?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  PM_EMAIL?: Maybe<Scalars['String']>;
  PM_FAMILY_CLUSTER?: Maybe<Scalars['String']>;
  PM_FIO_DRIVER?: Maybe<Scalars['String']>;
  PM_FIO_WIFES_HUSBAND?: Maybe<Scalars['String']>;
  PM_PHONE_OWNER?: Maybe<Scalars['String']>;
  _count?: Maybe<ClientCount>;
  cars: Array<Car>;
  clientLinks: Array<ClientLink>;
  commentWhenChangingManager?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lcr?: Maybe<Scalars['Float']>;
  manager: User;
  selfLinks: Array<ClientLink>;
  tasks: Array<Task>;
  uid?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};


export type LcrClientCarsArgs = {
  cursor?: InputMaybe<CarWhereUniqueInput>;
  distinct?: InputMaybe<Array<CarScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CarOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarWhereInput>;
};


export type LcrClientClientLinksArgs = {
  cursor?: InputMaybe<ClientLinkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type LcrClientSelfLinksArgs = {
  cursor?: InputMaybe<ClientLinkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type LcrClientTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cesarDeviceInfo: CesarResponse;
  createClientLink: ClientLink;
  createManyClientLink: AffectedRowsOutput;
  createRole: Role;
  createTaskStatus: TaskStatus;
  createUser: User;
  deleteClientLink?: Maybe<ClientLink>;
  deleteManyClientLink: AffectedRowsOutput;
  deviceInfo: DeviceInfoResponse;
  dzrtDeviceInfo: DzrtResponse;
  executeReport: ApiReport;
  export: ExportResponse;
  import: ImportResponse;
  importMetric: ImportMetricResponse;
  signIn: ApiToken;
  signUp: ApiToken;
  taskStatusUpdate?: Maybe<Task>;
  updateCarManager?: Maybe<Car>;
  updateClientDescription?: Maybe<Client>;
  updateClientLink?: Maybe<ClientLink>;
  updateManyClientLink: AffectedRowsOutput;
  updateRole?: Maybe<Role>;
  updateTaskStatus?: Maybe<TaskStatus>;
  updateUser?: Maybe<User>;
};


export type MutationCreateClientLinkArgs = {
  data: ApiClientLinkCreateInput;
};


export type MutationCreateManyClientLinkArgs = {
  data: Array<ApiClientLinkCreateInput>;
};


export type MutationCreateRoleArgs = {
  data: RoleCreateInput;
};


export type MutationCreateTaskStatusArgs = {
  data: TaskStatusCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteClientLinkArgs = {
  where: ClientLinkWhereUniqueInput;
};


export type MutationDeleteManyClientLinkArgs = {
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type MutationExecuteReportArgs = {
  data: ApiReportDataInput;
};


export type MutationExportArgs = {
  input?: InputMaybe<ExportInput>;
};


export type MutationImportArgs = {
  importDir?: InputMaybe<Scalars['String']>;
};


export type MutationImportMetricArgs = {
  date: Scalars['String'];
};


export type MutationSignInArgs = {
  input: SignIpInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationTaskStatusUpdateArgs = {
  input: UpdateTaskStatusInput;
};


export type MutationUpdateCarManagerArgs = {
  input: UpdateCarManagerInput;
};


export type MutationUpdateClientDescriptionArgs = {
  input: UpdateClientDescriptionInput;
};


export type MutationUpdateClientLinkArgs = {
  data: ClientLinkUpdateInput;
  where: ClientLinkWhereUniqueInput;
};


export type MutationUpdateManyClientLinkArgs = {
  data: ClientLinkUpdateManyMutationInput;
  where?: InputMaybe<ClientLinkWhereInput>;
};


export type MutationUpdateRoleArgs = {
  data: RoleUpdateInput;
  where: RoleWhereUniqueInput;
};


export type MutationUpdateTaskStatusArgs = {
  data: TaskStatusUpdateInput;
  where: TaskStatusWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumPointNameFilter = {
  equals?: InputMaybe<PointName>;
  in?: InputMaybe<Array<PointName>>;
  not?: InputMaybe<NestedEnumPointNameFilter>;
  notIn?: InputMaybe<Array<PointName>>;
};

export type NestedEnumRoleNameFilter = {
  equals?: InputMaybe<RoleName>;
  in?: InputMaybe<Array<RoleName>>;
  not?: InputMaybe<NestedEnumRoleNameFilter>;
  notIn?: InputMaybe<Array<RoleName>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Office = {
  __typename?: 'Office';
  CITY: Scalars['String'];
  DEALERID: Scalars['String'];
  NAME_AX: Scalars['String'];
  _count?: Maybe<OfficeCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  staff: Array<User>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type OfficeStaffArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type OfficeTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type OfficeCount = {
  __typename?: 'OfficeCount';
  staff: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type OfficeCreateNestedOneWithoutStaffInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutStaffInput>;
  create?: InputMaybe<OfficeCreateWithoutStaffInput>;
};

export type OfficeCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<OfficeCreateWithoutTasksInput>;
};

export type OfficeCreateOrConnectWithoutStaffInput = {
  create: OfficeCreateWithoutStaffInput;
  where: OfficeWhereUniqueInput;
};

export type OfficeCreateOrConnectWithoutTasksInput = {
  create: OfficeCreateWithoutTasksInput;
  where: OfficeWhereUniqueInput;
};

export type OfficeCreateWithoutStaffInput = {
  CITY: Scalars['String'];
  DEALERID: Scalars['String'];
  NAME_AX: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutOfficeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OfficeCreateWithoutTasksInput = {
  CITY: Scalars['String'];
  DEALERID: Scalars['String'];
  NAME_AX: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  staff?: InputMaybe<UserCreateNestedManyWithoutOfficeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OfficeOrderByWithRelationInput = {
  CITY?: InputMaybe<SortOrder>;
  DEALERID?: InputMaybe<SortOrder>;
  NAME_AX?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  staff?: InputMaybe<UserOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OfficeRelationFilter = {
  is?: InputMaybe<OfficeWhereInput>;
  isNot?: InputMaybe<OfficeWhereInput>;
};

export type OfficeUpdateOneRequiredWithoutStaffInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutStaffInput>;
  create?: InputMaybe<OfficeCreateWithoutStaffInput>;
  update?: InputMaybe<OfficeUpdateWithoutStaffInput>;
  upsert?: InputMaybe<OfficeUpsertWithoutStaffInput>;
};

export type OfficeUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<OfficeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfficeCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<OfficeCreateWithoutTasksInput>;
  update?: InputMaybe<OfficeUpdateWithoutTasksInput>;
  upsert?: InputMaybe<OfficeUpsertWithoutTasksInput>;
};

export type OfficeUpdateWithoutStaffInput = {
  CITY?: InputMaybe<StringFieldUpdateOperationsInput>;
  DEALERID?: InputMaybe<StringFieldUpdateOperationsInput>;
  NAME_AX?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutOfficeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OfficeUpdateWithoutTasksInput = {
  CITY?: InputMaybe<StringFieldUpdateOperationsInput>;
  DEALERID?: InputMaybe<StringFieldUpdateOperationsInput>;
  NAME_AX?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  staff?: InputMaybe<UserUpdateManyWithoutOfficeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OfficeUpsertWithoutStaffInput = {
  create: OfficeCreateWithoutStaffInput;
  update: OfficeUpdateWithoutStaffInput;
};

export type OfficeUpsertWithoutTasksInput = {
  create: OfficeCreateWithoutTasksInput;
  update: OfficeUpdateWithoutTasksInput;
};

export type OfficeWhereInput = {
  AND?: InputMaybe<Array<OfficeWhereInput>>;
  CITY?: InputMaybe<StringFilter>;
  DEALERID?: InputMaybe<StringFilter>;
  NAME_AX?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<OfficeWhereInput>>;
  OR?: InputMaybe<Array<OfficeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  staff?: InputMaybe<UserListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OfficeWhereUniqueInput = {
  DEALERID?: InputMaybe<Scalars['String']>;
  NAME_AX?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Виды точек бизнеса для импорта */
export enum PointName {
  Birthday = 'birthday',
  Credit = 'credit',
  KaskoApr = 'kaskoApr',
  KaskoNew = 'kaskoNew',
  KaskoRep = 'kaskoRep',
  Option = 'option',
  OptionRep = 'optionRep',
  OsagoApr = 'osagoApr',
  OsagoNew = 'osagoNew',
  OsagoRep = 'osagoRep',
  Service = 'service',
  Service0 = 'service0',
  SummerTyre = 'summerTyre',
  Tradein = 'tradein',
  Warranty = 'warranty',
  WinterTyre = 'winterTyre'
}

export type PointType = {
  __typename?: 'PointType';
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
  _count?: Maybe<PointTypeCount>;
  brands: Array<BrandPointType>;
  commentTemplates: Array<PointTypeCommentTemplate>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  imports: Array<ImportPointType>;
  maxDaysShift?: Maybe<Scalars['Int']>;
  minDaysShift?: Maybe<Scalars['Int']>;
  shiftCount?: Maybe<Scalars['Int']>;
  slaveBrands: Array<BrandPointType>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type PointTypeBrandsArgs = {
  cursor?: InputMaybe<BrandPointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BrandPointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BrandPointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BrandPointTypeWhereInput>;
};


export type PointTypeCommentTemplatesArgs = {
  cursor?: InputMaybe<PointTypeCommentTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointTypeCommentTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointTypeCommentTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PointTypeCommentTemplateWhereInput>;
};


export type PointTypeImportsArgs = {
  cursor?: InputMaybe<ImportPointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ImportPointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ImportPointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ImportPointTypeWhereInput>;
};


export type PointTypeSlaveBrandsArgs = {
  cursor?: InputMaybe<BrandPointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BrandPointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BrandPointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BrandPointTypeWhereInput>;
};


export type PointTypeTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type PointTypeCommentTemplate = {
  __typename?: 'PointTypeCommentTemplate';
  commentTemplates: CommentTemplates;
  commentTemplatesId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  pointType: PointType;
  pointTypeId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PointTypeCommentTemplateCreateManyCommentTemplatesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  pointTypeId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCommentTemplateCreateManyCommentTemplatesInputEnvelope = {
  data: Array<PointTypeCommentTemplateCreateManyCommentTemplatesInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PointTypeCommentTemplateCreateManyPointTypeInput = {
  commentTemplatesId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCommentTemplateCreateManyPointTypeInputEnvelope = {
  data: Array<PointTypeCommentTemplateCreateManyPointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PointTypeCommentTemplateCreateNestedManyWithoutCommentTemplatesInput = {
  connect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PointTypeCommentTemplateCreateOrConnectWithoutCommentTemplatesInput>>;
  create?: InputMaybe<Array<PointTypeCommentTemplateCreateWithoutCommentTemplatesInput>>;
  createMany?: InputMaybe<PointTypeCommentTemplateCreateManyCommentTemplatesInputEnvelope>;
};

export type PointTypeCommentTemplateCreateNestedManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PointTypeCommentTemplateCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<PointTypeCommentTemplateCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<PointTypeCommentTemplateCreateManyPointTypeInputEnvelope>;
};

export type PointTypeCommentTemplateCreateOrConnectWithoutCommentTemplatesInput = {
  create: PointTypeCommentTemplateCreateWithoutCommentTemplatesInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateCreateOrConnectWithoutPointTypeInput = {
  create: PointTypeCommentTemplateCreateWithoutPointTypeInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateCreateWithoutCommentTemplatesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  pointType: PointTypeCreateNestedOneWithoutCommentTemplatesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCommentTemplateCreateWithoutPointTypeInput = {
  commentTemplates: CommentTemplatesCreateNestedOneWithoutPointTypesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCommentTemplateListRelationFilter = {
  every?: InputMaybe<PointTypeCommentTemplateWhereInput>;
  none?: InputMaybe<PointTypeCommentTemplateWhereInput>;
  some?: InputMaybe<PointTypeCommentTemplateWhereInput>;
};

export type PointTypeCommentTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PointTypeCommentTemplateOrderByWithRelationInput = {
  commentTemplates?: InputMaybe<CommentTemplatesOrderByWithRelationInput>;
  commentTemplatesId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  pointType?: InputMaybe<PointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PointTypeCommentTemplatePointTypeIdCommentTemplatesIdCompoundUniqueInput = {
  commentTemplatesId: Scalars['String'];
  pointTypeId: Scalars['String'];
};

export enum PointTypeCommentTemplateScalarFieldEnum {
  CommentTemplatesId = 'commentTemplatesId',
  CreatedAt = 'createdAt',
  PointTypeId = 'pointTypeId',
  UpdatedAt = 'updatedAt'
}

export type PointTypeCommentTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<PointTypeCommentTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<PointTypeCommentTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<PointTypeCommentTemplateScalarWhereInput>>;
  commentTemplatesId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PointTypeCommentTemplateUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeCommentTemplateUpdateManyWithWhereWithoutCommentTemplatesInput = {
  data: PointTypeCommentTemplateUpdateManyMutationInput;
  where: PointTypeCommentTemplateScalarWhereInput;
};

export type PointTypeCommentTemplateUpdateManyWithWhereWithoutPointTypeInput = {
  data: PointTypeCommentTemplateUpdateManyMutationInput;
  where: PointTypeCommentTemplateScalarWhereInput;
};

export type PointTypeCommentTemplateUpdateManyWithoutCommentTemplatesInput = {
  connect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PointTypeCommentTemplateCreateOrConnectWithoutCommentTemplatesInput>>;
  create?: InputMaybe<Array<PointTypeCommentTemplateCreateWithoutCommentTemplatesInput>>;
  createMany?: InputMaybe<PointTypeCommentTemplateCreateManyCommentTemplatesInputEnvelope>;
  delete?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PointTypeCommentTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<PointTypeCommentTemplateUpdateWithWhereUniqueWithoutCommentTemplatesInput>>;
  updateMany?: InputMaybe<Array<PointTypeCommentTemplateUpdateManyWithWhereWithoutCommentTemplatesInput>>;
  upsert?: InputMaybe<Array<PointTypeCommentTemplateUpsertWithWhereUniqueWithoutCommentTemplatesInput>>;
};

export type PointTypeCommentTemplateUpdateManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PointTypeCommentTemplateCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<PointTypeCommentTemplateCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<PointTypeCommentTemplateCreateManyPointTypeInputEnvelope>;
  delete?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PointTypeCommentTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<PointTypeCommentTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<PointTypeCommentTemplateUpdateWithWhereUniqueWithoutPointTypeInput>>;
  updateMany?: InputMaybe<Array<PointTypeCommentTemplateUpdateManyWithWhereWithoutPointTypeInput>>;
  upsert?: InputMaybe<Array<PointTypeCommentTemplateUpsertWithWhereUniqueWithoutPointTypeInput>>;
};

export type PointTypeCommentTemplateUpdateWithWhereUniqueWithoutCommentTemplatesInput = {
  data: PointTypeCommentTemplateUpdateWithoutCommentTemplatesInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateUpdateWithWhereUniqueWithoutPointTypeInput = {
  data: PointTypeCommentTemplateUpdateWithoutPointTypeInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateUpdateWithoutCommentTemplatesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  pointType?: InputMaybe<PointTypeUpdateOneRequiredWithoutCommentTemplatesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeCommentTemplateUpdateWithoutPointTypeInput = {
  commentTemplates?: InputMaybe<CommentTemplatesUpdateOneRequiredWithoutPointTypesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeCommentTemplateUpsertWithWhereUniqueWithoutCommentTemplatesInput = {
  create: PointTypeCommentTemplateCreateWithoutCommentTemplatesInput;
  update: PointTypeCommentTemplateUpdateWithoutCommentTemplatesInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateUpsertWithWhereUniqueWithoutPointTypeInput = {
  create: PointTypeCommentTemplateCreateWithoutPointTypeInput;
  update: PointTypeCommentTemplateUpdateWithoutPointTypeInput;
  where: PointTypeCommentTemplateWhereUniqueInput;
};

export type PointTypeCommentTemplateWhereInput = {
  AND?: InputMaybe<Array<PointTypeCommentTemplateWhereInput>>;
  NOT?: InputMaybe<Array<PointTypeCommentTemplateWhereInput>>;
  OR?: InputMaybe<Array<PointTypeCommentTemplateWhereInput>>;
  commentTemplates?: InputMaybe<CommentTemplatesRelationFilter>;
  commentTemplatesId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  pointType?: InputMaybe<PointTypeRelationFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PointTypeCommentTemplateWhereUniqueInput = {
  pointTypeId_commentTemplatesId?: InputMaybe<PointTypeCommentTemplatePointTypeIdCommentTemplatesIdCompoundUniqueInput>;
};

export type PointTypeCount = {
  __typename?: 'PointTypeCount';
  brands: Scalars['Int'];
  commentTemplates: Scalars['Int'];
  imports: Scalars['Int'];
  slaveBrands: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type PointTypeCreateNestedOneWithoutBrandsInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutBrandsInput>;
  create?: InputMaybe<PointTypeCreateWithoutBrandsInput>;
};

export type PointTypeCreateNestedOneWithoutCommentTemplatesInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutCommentTemplatesInput>;
  create?: InputMaybe<PointTypeCreateWithoutCommentTemplatesInput>;
};

export type PointTypeCreateNestedOneWithoutSlaveBrandsInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutSlaveBrandsInput>;
  create?: InputMaybe<PointTypeCreateWithoutSlaveBrandsInput>;
};

export type PointTypeCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PointTypeCreateWithoutTasksInput>;
};

export type PointTypeCreateOrConnectWithoutBrandsInput = {
  create: PointTypeCreateWithoutBrandsInput;
  where: PointTypeWhereUniqueInput;
};

export type PointTypeCreateOrConnectWithoutCommentTemplatesInput = {
  create: PointTypeCreateWithoutCommentTemplatesInput;
  where: PointTypeWhereUniqueInput;
};

export type PointTypeCreateOrConnectWithoutSlaveBrandsInput = {
  create: PointTypeCreateWithoutSlaveBrandsInput;
  where: PointTypeWhereUniqueInput;
};

export type PointTypeCreateOrConnectWithoutTasksInput = {
  create: PointTypeCreateWithoutTasksInput;
  where: PointTypeWhereUniqueInput;
};

export type PointTypeCreateWithoutBrandsInput = {
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
  commentTemplates?: InputMaybe<PointTypeCommentTemplateCreateNestedManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imports?: InputMaybe<ImportPointTypeCreateNestedManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<Scalars['Int']>;
  minDaysShift?: InputMaybe<Scalars['Int']>;
  shiftCount?: InputMaybe<Scalars['Int']>;
  slaveBrands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutSlavePointTypeInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPointInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCreateWithoutCommentTemplatesInput = {
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
  brands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imports?: InputMaybe<ImportPointTypeCreateNestedManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<Scalars['Int']>;
  minDaysShift?: InputMaybe<Scalars['Int']>;
  shiftCount?: InputMaybe<Scalars['Int']>;
  slaveBrands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutSlavePointTypeInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPointInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCreateWithoutSlaveBrandsInput = {
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
  brands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutPointTypeInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateCreateNestedManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imports?: InputMaybe<ImportPointTypeCreateNestedManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<Scalars['Int']>;
  minDaysShift?: InputMaybe<Scalars['Int']>;
  shiftCount?: InputMaybe<Scalars['Int']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutPointInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeCreateWithoutTasksInput = {
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
  brands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutPointTypeInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateCreateNestedManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  imports?: InputMaybe<ImportPointTypeCreateNestedManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<Scalars['Int']>;
  minDaysShift?: InputMaybe<Scalars['Int']>;
  shiftCount?: InputMaybe<Scalars['Int']>;
  slaveBrands?: InputMaybe<BrandPointTypeCreateNestedManyWithoutSlavePointTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PointTypeOrderByWithRelationInput = {
  POINT_NAME?: InputMaybe<SortOrder>;
  POINT_TYPE?: InputMaybe<SortOrder>;
  brands?: InputMaybe<BrandPointTypeOrderByRelationAggregateInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  imports?: InputMaybe<ImportPointTypeOrderByRelationAggregateInput>;
  maxDaysShift?: InputMaybe<SortOrder>;
  minDaysShift?: InputMaybe<SortOrder>;
  shiftCount?: InputMaybe<SortOrder>;
  slaveBrands?: InputMaybe<BrandPointTypeOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PointTypePoint_Typepoint_NameCompoundUniqueInput = {
  POINT_NAME: Scalars['String'];
  POINT_TYPE: Scalars['String'];
};

export type PointTypeRelationFilter = {
  is?: InputMaybe<PointTypeWhereInput>;
  isNot?: InputMaybe<PointTypeWhereInput>;
};

export enum PointTypeScalarFieldEnum {
  PointName = 'POINT_NAME',
  PointType = 'POINT_TYPE',
  CreatedAt = 'createdAt',
  Id = 'id',
  MaxDaysShift = 'maxDaysShift',
  MinDaysShift = 'minDaysShift',
  ShiftCount = 'shiftCount',
  UpdatedAt = 'updatedAt'
}

export type PointTypeUpdateOneRequiredWithoutBrandsInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutBrandsInput>;
  create?: InputMaybe<PointTypeCreateWithoutBrandsInput>;
  update?: InputMaybe<PointTypeUpdateWithoutBrandsInput>;
  upsert?: InputMaybe<PointTypeUpsertWithoutBrandsInput>;
};

export type PointTypeUpdateOneRequiredWithoutCommentTemplatesInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutCommentTemplatesInput>;
  create?: InputMaybe<PointTypeCreateWithoutCommentTemplatesInput>;
  update?: InputMaybe<PointTypeUpdateWithoutCommentTemplatesInput>;
  upsert?: InputMaybe<PointTypeUpsertWithoutCommentTemplatesInput>;
};

export type PointTypeUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<PointTypeCreateWithoutTasksInput>;
  update?: InputMaybe<PointTypeUpdateWithoutTasksInput>;
  upsert?: InputMaybe<PointTypeUpsertWithoutTasksInput>;
};

export type PointTypeUpdateOneWithoutSlaveBrandsInput = {
  connect?: InputMaybe<PointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PointTypeCreateOrConnectWithoutSlaveBrandsInput>;
  create?: InputMaybe<PointTypeCreateWithoutSlaveBrandsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PointTypeUpdateWithoutSlaveBrandsInput>;
  upsert?: InputMaybe<PointTypeUpsertWithoutSlaveBrandsInput>;
};

export type PointTypeUpdateWithoutBrandsInput = {
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateUpdateManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imports?: InputMaybe<ImportPointTypeUpdateManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shiftCount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  slaveBrands?: InputMaybe<BrandPointTypeUpdateManyWithoutSlavePointTypeInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPointInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeUpdateWithoutCommentTemplatesInput = {
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  brands?: InputMaybe<BrandPointTypeUpdateManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imports?: InputMaybe<ImportPointTypeUpdateManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shiftCount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  slaveBrands?: InputMaybe<BrandPointTypeUpdateManyWithoutSlavePointTypeInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPointInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeUpdateWithoutSlaveBrandsInput = {
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  brands?: InputMaybe<BrandPointTypeUpdateManyWithoutPointTypeInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateUpdateManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imports?: InputMaybe<ImportPointTypeUpdateManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shiftCount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutPointInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeUpdateWithoutTasksInput = {
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  brands?: InputMaybe<BrandPointTypeUpdateManyWithoutPointTypeInput>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateUpdateManyWithoutPointTypeInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imports?: InputMaybe<ImportPointTypeUpdateManyWithoutPointTypeInput>;
  maxDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  minDaysShift?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  shiftCount?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  slaveBrands?: InputMaybe<BrandPointTypeUpdateManyWithoutSlavePointTypeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PointTypeUpsertWithoutBrandsInput = {
  create: PointTypeCreateWithoutBrandsInput;
  update: PointTypeUpdateWithoutBrandsInput;
};

export type PointTypeUpsertWithoutCommentTemplatesInput = {
  create: PointTypeCreateWithoutCommentTemplatesInput;
  update: PointTypeUpdateWithoutCommentTemplatesInput;
};

export type PointTypeUpsertWithoutSlaveBrandsInput = {
  create: PointTypeCreateWithoutSlaveBrandsInput;
  update: PointTypeUpdateWithoutSlaveBrandsInput;
};

export type PointTypeUpsertWithoutTasksInput = {
  create: PointTypeCreateWithoutTasksInput;
  update: PointTypeUpdateWithoutTasksInput;
};

export type PointTypeWhereInput = {
  AND?: InputMaybe<Array<PointTypeWhereInput>>;
  NOT?: InputMaybe<Array<PointTypeWhereInput>>;
  OR?: InputMaybe<Array<PointTypeWhereInput>>;
  POINT_NAME?: InputMaybe<StringFilter>;
  POINT_TYPE?: InputMaybe<StringFilter>;
  brands?: InputMaybe<BrandPointTypeListRelationFilter>;
  commentTemplates?: InputMaybe<PointTypeCommentTemplateListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  imports?: InputMaybe<ImportPointTypeListRelationFilter>;
  maxDaysShift?: InputMaybe<IntNullableFilter>;
  minDaysShift?: InputMaybe<IntNullableFilter>;
  shiftCount?: InputMaybe<IntNullableFilter>;
  slaveBrands?: InputMaybe<BrandPointTypeListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PointTypeWhereUniqueInput = {
  POINT_TYPE_POINT_NAME?: InputMaybe<PointTypePoint_Typepoint_NameCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allClients: Array<ApiClient>;
  client?: Maybe<LcrClient>;
  clientLinkHistories: Array<ClientLinkHistory>;
  clientLinkTypes: Array<ClientLinkType>;
  clientLinks: Array<ApiClientLink>;
  clients: Array<ClientList>;
  findFirstClient?: Maybe<Client>;
  findFirstTask?: Maybe<Task>;
  findManyCommentTemplates: Array<CommentTemplates>;
  me: ApiUser;
  pointTypes: Array<PointType>;
  reports: Array<Report>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  task?: Maybe<Task>;
  taskStatuses: Array<TaskStatus>;
  tasks: Array<TaskList>;
  user?: Maybe<User>;
  users: Array<User>;
  version: VersionResponse;
};


export type QueryAllClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryClientArgs = {
  where: ClientWhereUniqueInput;
};


export type QueryClientLinkHistoriesArgs = {
  cursor?: InputMaybe<ClientLinkHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkHistoryWhereInput>;
};


export type QueryClientLinkTypesArgs = {
  cursor?: InputMaybe<ClientLinkTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientLinkTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientLinkTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientLinkTypeWhereInput>;
};


export type QueryClientLinksArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryFindFirstClientArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type QueryFindFirstTaskArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryFindManyCommentTemplatesArgs = {
  cursor?: InputMaybe<CommentTemplatesWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentTemplatesScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentTemplatesOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommentTemplatesWhereInput>;
};


export type QueryPointTypesArgs = {
  cursor?: InputMaybe<PointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PointTypeWhereInput>;
};


export type QueryRoleArgs = {
  where: RoleWhereUniqueInput;
};


export type QueryRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QueryTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type QueryTaskStatusesArgs = {
  cursor?: InputMaybe<TaskStatusWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskStatusScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskStatusOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskStatusWhereInput>;
};


export type QueryTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['DateTime'];
  /** Text description. */
  description: Scalars['String'];
  /** filename Text */
  filename: Scalars['String'];
  id: Scalars['String'];
  /** Short name. */
  name: Scalars['String'];
  /** Report SQL-query with placeholders */
  query: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Role = {
  __typename?: 'Role';
  _count?: Maybe<RoleCount>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: RoleName;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};


export type RoleUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type RoleCount = {
  __typename?: 'RoleCount';
  users: Scalars['Int'];
};

export type RoleCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: RoleName;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutRolesInput>;
};

export type RoleCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
};

export type RoleCreateOrConnectWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: RoleName;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RoleListRelationFilter = {
  every?: InputMaybe<RoleWhereInput>;
  none?: InputMaybe<RoleWhereInput>;
  some?: InputMaybe<RoleWhereInput>;
};

export enum RoleName {
  Admin = 'ADMIN',
  Director = 'DIRECTOR',
  Manager = 'MANAGER',
  User = 'USER'
}

export type RoleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type RoleOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export enum RoleScalarFieldEnum {
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type RoleScalarWhereInput = {
  AND?: InputMaybe<Array<RoleScalarWhereInput>>;
  NOT?: InputMaybe<Array<RoleScalarWhereInput>>;
  OR?: InputMaybe<Array<RoleScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumRoleNameFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type RoleUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumRoleNameFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutRolesInput>;
};

export type RoleUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumRoleNameFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpdateManyWithWhereWithoutUsersInput = {
  data: RoleUpdateManyMutationInput;
  where: RoleScalarWhereInput;
};

export type RoleUpdateManyWithoutUsersInput = {
  connect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<RoleCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<RoleCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<RoleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<RoleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<RoleWhereUniqueInput>>;
  set?: InputMaybe<Array<RoleWhereUniqueInput>>;
  update?: InputMaybe<Array<RoleUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<RoleUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<RoleUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type RoleUpdateWithWhereUniqueWithoutUsersInput = {
  data: RoleUpdateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<EnumRoleNameFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type RoleUpsertWithWhereUniqueWithoutUsersInput = {
  create: RoleCreateWithoutUsersInput;
  update: RoleUpdateWithoutUsersInput;
  where: RoleWhereUniqueInput;
};

export type RoleWhereInput = {
  AND?: InputMaybe<Array<RoleWhereInput>>;
  NOT?: InputMaybe<Array<RoleWhereInput>>;
  OR?: InputMaybe<Array<RoleWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<EnumRoleNameFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type RoleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<RoleName>;
};

export type SignIpInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpInput = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password: Scalars['String'];
  surName?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: Maybe<Scalars['String']>;
  CLIENT_EMAIL?: Maybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  CLIENT_PHONE?: Maybe<Scalars['String']>;
  CLOSE_DATE?: Maybe<Scalars['DateTime']>;
  CLOSE_STATUS?: Maybe<Scalars['Int']>;
  COMMENT_OPTIONS?: Maybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: Maybe<Scalars['String']>;
  CREDIT?: Maybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: Maybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: Maybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: Maybe<Scalars['DateTime']>;
  FACT_DATE?: Maybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: Maybe<Scalars['DateTime']>;
  ID_COL: Scalars['String'];
  ID_COL_INT: Scalars['Int'];
  INFO?: Maybe<Scalars['String']>;
  INSERT_DATE?: Maybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: Maybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: Maybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: Maybe<Scalars['String']>;
  LCR_ACCESS_PERC?: Maybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: Maybe<Scalars['Float']>;
  LCR_KASKO_PERC?: Maybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: Maybe<Scalars['Float']>;
  LCR_PERC?: Maybe<Scalars['Float']>;
  LCR_PM_PERC?: Maybe<Scalars['Float']>;
  MILEAGE?: Maybe<Scalars['Float']>;
  MODIFY_DATE?: Maybe<Scalars['DateTime']>;
  MODIFY_STATUS?: Maybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: Maybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: Maybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: Maybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: Maybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: Maybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  PM_COMMENTS?: Maybe<Scalars['String']>;
  PM_CONTACT_DATE?: Maybe<Scalars['DateTime']>;
  PM_INSUR_END?: Maybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: Maybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: Maybe<Scalars['String']>;
  PM_INSUR_START?: Maybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: Maybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: Maybe<Scalars['String']>;
  PM_PHONE?: Maybe<Scalars['String']>;
  PM_PLATE_NUM?: Maybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: Maybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: Maybe<Scalars['String']>;
  PM_WARRANTY_END?: Maybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: Maybe<Scalars['DateTime']>;
  REMINDER_TWO?: Maybe<Scalars['DateTime']>;
  SALESMAN?: Maybe<Scalars['String']>;
  TRADE_IN?: Maybe<Scalars['String']>;
  Vladelec_ID?: Maybe<Scalars['Int']>;
  _count?: Maybe<TaskCount>;
  car: Car;
  carId: Scalars['String'];
  client: Client;
  clientId: Scalars['String'];
  commentTemplate?: Maybe<CommentTemplates>;
  commentTemplatesId?: Maybe<Scalars['String']>;
  company: Company;
  companyId: Scalars['String'];
  id: Scalars['String'];
  lastCommentAuthor?: Maybe<User>;
  lastCommentAuthorId?: Maybe<Scalars['String']>;
  manager: User;
  office: Office;
  officeId: Scalars['String'];
  point: PointType;
  pointTypeId: Scalars['String'];
  status: TaskStatus;
  statusHistory: Array<TaskStatusHistory>;
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};


export type TaskStatusHistoryArgs = {
  cursor?: InputMaybe<TaskStatusHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskStatusHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskStatusHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskStatusHistoryWhereInput>;
};

export type TaskCar_Vinpoint_Prioritypoint_TypeCompoundUniqueInput = {
  CAR_VIN: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
};

export type TaskCount = {
  __typename?: 'TaskCount';
  statusHistory: Scalars['Int'];
};

export type TaskCreateManyCarInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyCarInputEnvelope = {
  data: Array<TaskCreateManyCarInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyClientInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyClientInputEnvelope = {
  data: Array<TaskCreateManyClientInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyCommentTemplateInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyCommentTemplateInputEnvelope = {
  data: Array<TaskCreateManyCommentTemplateInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyLastCommentAuthorInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyLastCommentAuthorInputEnvelope = {
  data: Array<TaskCreateManyLastCommentAuthorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyManagerInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
};

export type TaskCreateManyManagerInputEnvelope = {
  data: Array<TaskCreateManyManagerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyOfficeInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  pointTypeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyOfficeInputEnvelope = {
  data: Array<TaskCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyPointInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  taskStatusId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyPointInputEnvelope = {
  data: Array<TaskCreateManyPointInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateManyStatusInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  carId: Scalars['String'];
  clientId: Scalars['String'];
  commentTemplatesId?: InputMaybe<Scalars['String']>;
  companyId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthorId?: InputMaybe<Scalars['String']>;
  officeId: Scalars['String'];
  pointTypeId: Scalars['String'];
  userId: Scalars['String'];
};

export type TaskCreateManyStatusInputEnvelope = {
  data: Array<TaskCreateManyStatusInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskCreateNestedManyWithoutCarInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCarInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCarInput>>;
  createMany?: InputMaybe<TaskCreateManyCarInputEnvelope>;
};

export type TaskCreateNestedManyWithoutClientInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutClientInput>>;
  createMany?: InputMaybe<TaskCreateManyClientInputEnvelope>;
};

export type TaskCreateNestedManyWithoutCommentTemplateInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCommentTemplateInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCommentTemplateInput>>;
  createMany?: InputMaybe<TaskCreateManyCommentTemplateInputEnvelope>;
};

export type TaskCreateNestedManyWithoutLastCommentAuthorInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutLastCommentAuthorInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutLastCommentAuthorInput>>;
  createMany?: InputMaybe<TaskCreateManyLastCommentAuthorInputEnvelope>;
};

export type TaskCreateNestedManyWithoutManagerInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutManagerInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutManagerInput>>;
  createMany?: InputMaybe<TaskCreateManyManagerInputEnvelope>;
};

export type TaskCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<TaskCreateManyOfficeInputEnvelope>;
};

export type TaskCreateNestedManyWithoutPointInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutPointInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutPointInput>>;
  createMany?: InputMaybe<TaskCreateManyPointInputEnvelope>;
};

export type TaskCreateNestedManyWithoutStatusInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutStatusInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutStatusInput>>;
  createMany?: InputMaybe<TaskCreateManyStatusInputEnvelope>;
};

export type TaskCreateNestedOneWithoutStatusHistoryInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutStatusHistoryInput>;
  create?: InputMaybe<TaskCreateWithoutStatusHistoryInput>;
};

export type TaskCreateOrConnectWithoutCarInput = {
  create: TaskCreateWithoutCarInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutClientInput = {
  create: TaskCreateWithoutClientInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutCommentTemplateInput = {
  create: TaskCreateWithoutCommentTemplateInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutLastCommentAuthorInput = {
  create: TaskCreateWithoutLastCommentAuthorInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutManagerInput = {
  create: TaskCreateWithoutManagerInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutOfficeInput = {
  create: TaskCreateWithoutOfficeInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutPointInput = {
  create: TaskCreateWithoutPointInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutStatusHistoryInput = {
  create: TaskCreateWithoutStatusHistoryInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateOrConnectWithoutStatusInput = {
  create: TaskCreateWithoutStatusInput;
  where: TaskWhereUniqueInput;
};

export type TaskCreateWithoutCarInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutClientInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutCommentTemplateInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutLastCommentAuthorInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutManagerInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutOfficeInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutPointInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutStatusHistoryInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  status: TaskStatusCreateNestedOneWithoutTasksInput;
};

export type TaskCreateWithoutStatusInput = {
  BRAND: Scalars['String'];
  CAR_MODEL: Scalars['String'];
  CAR_VIN: Scalars['String'];
  CLIENT?: InputMaybe<Scalars['String']>;
  CLIENT_EMAIL?: InputMaybe<Scalars['String']>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  CLIENT_PHONE?: InputMaybe<Scalars['String']>;
  CLOSE_DATE?: InputMaybe<Scalars['DateTime']>;
  CLOSE_STATUS?: InputMaybe<Scalars['Int']>;
  COMMENT_OPTIONS?: InputMaybe<Scalars['String']>;
  COMMENT_TYPE_ONE?: InputMaybe<Scalars['String']>;
  CREDIT?: InputMaybe<Scalars['String']>;
  CREDIT_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  CRM_FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_DATE?: InputMaybe<Scalars['DateTime']>;
  FACT_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  ID_COL?: InputMaybe<Scalars['String']>;
  ID_COL_INT?: InputMaybe<Scalars['Int']>;
  INFO?: InputMaybe<Scalars['String']>;
  INSERT_DATE?: InputMaybe<Scalars['DateTime']>;
  JURID_STATUS: Scalars['String'];
  KASKO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  KPIS: Scalars['String'];
  LAST_CHANGE_DATE?: InputMaybe<Scalars['DateTime']>;
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  LCR_ACCESS_PERC?: InputMaybe<Scalars['Float']>;
  LCR_EXTWARR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_KASKO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_OSAGO_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PERC?: InputMaybe<Scalars['Float']>;
  LCR_PM_PERC?: InputMaybe<Scalars['Float']>;
  MILEAGE?: InputMaybe<Scalars['Float']>;
  MODIFY_DATE?: InputMaybe<Scalars['DateTime']>;
  MODIFY_STATUS?: InputMaybe<Scalars['Int']>;
  NUMBER_MONTHS_AFTER_ISSUANCE: Scalars['Float'];
  OSAGO_NV_SALEDATE?: InputMaybe<Scalars['DateTime']>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<Scalars['Float']>;
  PLAN_DATE: Scalars['DateTime'];
  PLAN_MONTH: Scalars['Int'];
  PLAN_PRED_DATE?: InputMaybe<Scalars['DateTime']>;
  PLAN_YEAR: Scalars['Int'];
  PM_BASIC_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_ADDRESS?: InputMaybe<Scalars['String']>;
  PM_CLIENT_NAME?: InputMaybe<Scalars['String']>;
  PM_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_INSUR_END?: InputMaybe<Scalars['String']>;
  PM_INSUR_POLICENUM?: InputMaybe<Scalars['String']>;
  PM_INSUR_PRODUCT?: InputMaybe<Scalars['String']>;
  PM_INSUR_START?: InputMaybe<Scalars['String']>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<Scalars['DateTime']>;
  PM_PERSONAL_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_PHONE?: InputMaybe<Scalars['String']>;
  PM_PLATE_NUM?: InputMaybe<Scalars['String']>;
  PM_PRIVATE_COMMENTS?: InputMaybe<Scalars['String']>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<Scalars['String']>;
  PM_WARRANTY_END?: InputMaybe<Scalars['String']>;
  POINT_NAME: Scalars['String'];
  POINT_PRIORITY: Scalars['Int'];
  POINT_TYPE: Scalars['String'];
  REMINDER_ONE: Scalars['DateTime'];
  REMINDER_THREE?: InputMaybe<Scalars['DateTime']>;
  REMINDER_TWO?: InputMaybe<Scalars['DateTime']>;
  SALESMAN?: InputMaybe<Scalars['String']>;
  TRADE_IN?: InputMaybe<Scalars['String']>;
  Vladelec_ID?: InputMaybe<Scalars['Int']>;
  car: CarCreateNestedOneWithoutTasksInput;
  client: ClientCreateNestedOneWithoutTasksInput;
  commentTemplate?: InputMaybe<CommentTemplatesCreateNestedOneWithoutTasksInput>;
  company: CompanyCreateNestedOneWithoutTasksInput;
  id?: InputMaybe<Scalars['String']>;
  lastCommentAuthor?: InputMaybe<UserCreateNestedOneWithoutCommentedTasksInput>;
  manager: UserCreateNestedOneWithoutTasksInput;
  office: OfficeCreateNestedOneWithoutTasksInput;
  point: PointTypeCreateNestedOneWithoutTasksInput;
  statusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskInput>;
};

export type TaskList = {
  __typename?: 'TaskList';
  CLIENT_OR_PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  CRM_FACT_DATE?: Maybe<Scalars['DateTime']>;
  CRM_PLAN_DATE: Scalars['DateTime'];
  CURRENT_STATUS_DATE?: Maybe<Scalars['DateTime']>;
  ID_COL: Scalars['String'];
  LAST_COMMENT_CLIENT?: Maybe<Scalars['String']>;
  PLAN_DATE: Scalars['DateTime'];
  POINT_PRIORITY?: Maybe<Scalars['Float']>;
  car: Car;
  carId: Scalars['String'];
  client: TaskListClient;
  clientId: Scalars['String'];
  id: Scalars['String'];
  office: Office;
  officeId: Scalars['String'];
  point: PointType;
  pointTypeId: Scalars['String'];
  status: TaskStatus;
  statusHistory: Array<TaskStatusHistory>;
  taskStatusId: Scalars['String'];
};

export type TaskListClient = {
  __typename?: 'TaskListClient';
  CLIENT?: Maybe<Scalars['String']>;
  CLIENT_EMAIL?: Maybe<Scalars['String']>;
  CLIENT_PHONE?: Maybe<Scalars['String']>;
  PM_CLIENT_NAME?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type TaskListRelationFilter = {
  every?: InputMaybe<TaskWhereInput>;
  none?: InputMaybe<TaskWhereInput>;
  some?: InputMaybe<TaskWhereInput>;
};

export type TaskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskOrderByWithRelationInput = {
  BRAND?: InputMaybe<SortOrder>;
  CAR_MODEL?: InputMaybe<SortOrder>;
  CAR_VIN?: InputMaybe<SortOrder>;
  CLIENT?: InputMaybe<SortOrder>;
  CLIENT_EMAIL?: InputMaybe<SortOrder>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<SortOrder>;
  CLIENT_PHONE?: InputMaybe<SortOrder>;
  CLOSE_DATE?: InputMaybe<SortOrder>;
  CLOSE_STATUS?: InputMaybe<SortOrder>;
  COMMENT_OPTIONS?: InputMaybe<SortOrder>;
  COMMENT_TYPE_ONE?: InputMaybe<SortOrder>;
  CREDIT?: InputMaybe<SortOrder>;
  CREDIT_NV_SALEDATE?: InputMaybe<SortOrder>;
  CRM_FACT_DATE?: InputMaybe<SortOrder>;
  CRM_PLAN_DATE?: InputMaybe<SortOrder>;
  CURRENT_STATUS_DATE?: InputMaybe<SortOrder>;
  FACT_DATE?: InputMaybe<SortOrder>;
  FACT_PRED_DATE?: InputMaybe<SortOrder>;
  ID_COL?: InputMaybe<SortOrder>;
  ID_COL_INT?: InputMaybe<SortOrder>;
  INFO?: InputMaybe<SortOrder>;
  INSERT_DATE?: InputMaybe<SortOrder>;
  JURID_STATUS?: InputMaybe<SortOrder>;
  KASKO_NV_SALEDATE?: InputMaybe<SortOrder>;
  KPIS?: InputMaybe<SortOrder>;
  LAST_CHANGE_DATE?: InputMaybe<SortOrder>;
  LAST_COMMENT_CLIENT?: InputMaybe<SortOrder>;
  LCR_ACCESS_PERC?: InputMaybe<SortOrder>;
  LCR_EXTWARR_PERC?: InputMaybe<SortOrder>;
  LCR_KASKO_PERC?: InputMaybe<SortOrder>;
  LCR_OSAGO_PERC?: InputMaybe<SortOrder>;
  LCR_PERC?: InputMaybe<SortOrder>;
  LCR_PM_PERC?: InputMaybe<SortOrder>;
  MILEAGE?: InputMaybe<SortOrder>;
  MODIFY_DATE?: InputMaybe<SortOrder>;
  MODIFY_STATUS?: InputMaybe<SortOrder>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<SortOrder>;
  OSAGO_NV_SALEDATE?: InputMaybe<SortOrder>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<SortOrder>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<SortOrder>;
  PLAN_DATE?: InputMaybe<SortOrder>;
  PLAN_MONTH?: InputMaybe<SortOrder>;
  PLAN_PRED_DATE?: InputMaybe<SortOrder>;
  PLAN_YEAR?: InputMaybe<SortOrder>;
  PM_BASIC_COMMENTS?: InputMaybe<SortOrder>;
  PM_CLIENT_ADDRESS?: InputMaybe<SortOrder>;
  PM_CLIENT_NAME?: InputMaybe<SortOrder>;
  PM_COMMENTS?: InputMaybe<SortOrder>;
  PM_CONTACT_DATE?: InputMaybe<SortOrder>;
  PM_INSUR_END?: InputMaybe<SortOrder>;
  PM_INSUR_POLICENUM?: InputMaybe<SortOrder>;
  PM_INSUR_PRODUCT?: InputMaybe<SortOrder>;
  PM_INSUR_START?: InputMaybe<SortOrder>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<SortOrder>;
  PM_PERSONAL_COMMENTS?: InputMaybe<SortOrder>;
  PM_PHONE?: InputMaybe<SortOrder>;
  PM_PLATE_NUM?: InputMaybe<SortOrder>;
  PM_PRIVATE_COMMENTS?: InputMaybe<SortOrder>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<SortOrder>;
  PM_WARRANTY_END?: InputMaybe<SortOrder>;
  POINT_NAME?: InputMaybe<SortOrder>;
  POINT_PRIORITY?: InputMaybe<SortOrder>;
  POINT_TYPE?: InputMaybe<SortOrder>;
  REMINDER_ONE?: InputMaybe<SortOrder>;
  REMINDER_THREE?: InputMaybe<SortOrder>;
  REMINDER_TWO?: InputMaybe<SortOrder>;
  SALESMAN?: InputMaybe<SortOrder>;
  TRADE_IN?: InputMaybe<SortOrder>;
  Vladelec_ID?: InputMaybe<SortOrder>;
  car?: InputMaybe<CarOrderByWithRelationInput>;
  carId?: InputMaybe<SortOrder>;
  client?: InputMaybe<ClientOrderByWithRelationInput>;
  clientId?: InputMaybe<SortOrder>;
  commentTemplate?: InputMaybe<CommentTemplatesOrderByWithRelationInput>;
  commentTemplatesId?: InputMaybe<SortOrder>;
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastCommentAuthor?: InputMaybe<UserOrderByWithRelationInput>;
  lastCommentAuthorId?: InputMaybe<SortOrder>;
  manager?: InputMaybe<UserOrderByWithRelationInput>;
  office?: InputMaybe<OfficeOrderByWithRelationInput>;
  officeId?: InputMaybe<SortOrder>;
  point?: InputMaybe<PointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrder>;
  status?: InputMaybe<TaskStatusOrderByWithRelationInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryOrderByRelationAggregateInput>;
  taskStatusId?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type TaskRelationFilter = {
  is?: InputMaybe<TaskWhereInput>;
  isNot?: InputMaybe<TaskWhereInput>;
};

export enum TaskScalarFieldEnum {
  Brand = 'BRAND',
  CarModel = 'CAR_MODEL',
  CarVin = 'CAR_VIN',
  Client = 'CLIENT',
  ClientEmail = 'CLIENT_EMAIL',
  ClientOrPmClientName = 'CLIENT_OR_PM_CLIENT_NAME',
  ClientPhone = 'CLIENT_PHONE',
  CloseDate = 'CLOSE_DATE',
  CloseStatus = 'CLOSE_STATUS',
  CommentOptions = 'COMMENT_OPTIONS',
  CommentTypeOne = 'COMMENT_TYPE_ONE',
  Credit = 'CREDIT',
  CreditNvSaledate = 'CREDIT_NV_SALEDATE',
  CrmFactDate = 'CRM_FACT_DATE',
  CrmPlanDate = 'CRM_PLAN_DATE',
  CurrentStatusDate = 'CURRENT_STATUS_DATE',
  FactDate = 'FACT_DATE',
  FactPredDate = 'FACT_PRED_DATE',
  IdCol = 'ID_COL',
  IdColInt = 'ID_COL_INT',
  Info = 'INFO',
  InsertDate = 'INSERT_DATE',
  JuridStatus = 'JURID_STATUS',
  KaskoNvSaledate = 'KASKO_NV_SALEDATE',
  Kpis = 'KPIS',
  LastChangeDate = 'LAST_CHANGE_DATE',
  LastCommentClient = 'LAST_COMMENT_CLIENT',
  LcrAccessPerc = 'LCR_ACCESS_PERC',
  LcrExtwarrPerc = 'LCR_EXTWARR_PERC',
  LcrKaskoPerc = 'LCR_KASKO_PERC',
  LcrOsagoPerc = 'LCR_OSAGO_PERC',
  LcrPerc = 'LCR_PERC',
  LcrPmPerc = 'LCR_PM_PERC',
  Mileage = 'MILEAGE',
  ModifyDate = 'MODIFY_DATE',
  ModifyStatus = 'MODIFY_STATUS',
  NumberMonthsAfterIssuance = 'NUMBER_MONTHS_AFTER_ISSUANCE',
  OsagoNvSaledate = 'OSAGO_NV_SALEDATE',
  Period_10KKmAfterAfterTo = 'PERIOD_10K_KM_AFTER_AFTER_TO',
  Period_10KKmBeforeAfterTo = 'PERIOD_10K_KM_BEFORE_AFTER_TO',
  PlanDate = 'PLAN_DATE',
  PlanMonth = 'PLAN_MONTH',
  PlanPredDate = 'PLAN_PRED_DATE',
  PlanYear = 'PLAN_YEAR',
  PmBasicComments = 'PM_BASIC_COMMENTS',
  PmClientAddress = 'PM_CLIENT_ADDRESS',
  PmClientName = 'PM_CLIENT_NAME',
  PmComments = 'PM_COMMENTS',
  PmContactDate = 'PM_CONTACT_DATE',
  PmInsurEnd = 'PM_INSUR_END',
  PmInsurPolicenum = 'PM_INSUR_POLICENUM',
  PmInsurProduct = 'PM_INSUR_PRODUCT',
  PmInsurStart = 'PM_INSUR_START',
  PmNextContactDate = 'PM_NEXT_CONTACT_DATE',
  PmPersonalComments = 'PM_PERSONAL_COMMENTS',
  PmPhone = 'PM_PHONE',
  PmPlateNum = 'PM_PLATE_NUM',
  PmPrivateComments = 'PM_PRIVATE_COMMENTS',
  PmVehicleRegistrationPlate = 'PM_VEHICLE_REGISTRATION_PLATE',
  PmWarrantyEnd = 'PM_WARRANTY_END',
  PointName = 'POINT_NAME',
  PointPriority = 'POINT_PRIORITY',
  PointType = 'POINT_TYPE',
  ReminderOne = 'REMINDER_ONE',
  ReminderThree = 'REMINDER_THREE',
  ReminderTwo = 'REMINDER_TWO',
  Salesman = 'SALESMAN',
  TradeIn = 'TRADE_IN',
  VladelecId = 'Vladelec_ID',
  CarId = 'carId',
  ClientId = 'clientId',
  CommentTemplatesId = 'commentTemplatesId',
  CompanyId = 'companyId',
  Id = 'id',
  LastCommentAuthorId = 'lastCommentAuthorId',
  OfficeId = 'officeId',
  PointTypeId = 'pointTypeId',
  TaskStatusId = 'taskStatusId',
  UserId = 'userId'
}

export type TaskScalarWhereInput = {
  AND?: InputMaybe<Array<TaskScalarWhereInput>>;
  BRAND?: InputMaybe<StringFilter>;
  CAR_MODEL?: InputMaybe<StringFilter>;
  CAR_VIN?: InputMaybe<StringFilter>;
  CLIENT?: InputMaybe<StringNullableFilter>;
  CLIENT_EMAIL?: InputMaybe<StringNullableFilter>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  CLIENT_PHONE?: InputMaybe<StringNullableFilter>;
  CLOSE_DATE?: InputMaybe<DateTimeNullableFilter>;
  CLOSE_STATUS?: InputMaybe<IntNullableFilter>;
  COMMENT_OPTIONS?: InputMaybe<StringNullableFilter>;
  COMMENT_TYPE_ONE?: InputMaybe<StringNullableFilter>;
  CREDIT?: InputMaybe<StringNullableFilter>;
  CREDIT_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  CRM_FACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFilter>;
  CURRENT_STATUS_DATE?: InputMaybe<DateTimeNullableFilter>;
  FACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  FACT_PRED_DATE?: InputMaybe<DateTimeNullableFilter>;
  ID_COL?: InputMaybe<StringFilter>;
  ID_COL_INT?: InputMaybe<IntFilter>;
  INFO?: InputMaybe<StringNullableFilter>;
  INSERT_DATE?: InputMaybe<DateTimeNullableFilter>;
  JURID_STATUS?: InputMaybe<StringFilter>;
  KASKO_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  KPIS?: InputMaybe<StringFilter>;
  LAST_CHANGE_DATE?: InputMaybe<DateTimeNullableFilter>;
  LAST_COMMENT_CLIENT?: InputMaybe<StringNullableFilter>;
  LCR_ACCESS_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_EXTWARR_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_KASKO_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_OSAGO_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_PM_PERC?: InputMaybe<FloatNullableFilter>;
  MILEAGE?: InputMaybe<FloatNullableFilter>;
  MODIFY_DATE?: InputMaybe<DateTimeNullableFilter>;
  MODIFY_STATUS?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<TaskScalarWhereInput>>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<TaskScalarWhereInput>>;
  OSAGO_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<FloatNullableFilter>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<FloatNullableFilter>;
  PLAN_DATE?: InputMaybe<DateTimeFilter>;
  PLAN_MONTH?: InputMaybe<IntFilter>;
  PLAN_PRED_DATE?: InputMaybe<DateTimeNullableFilter>;
  PLAN_YEAR?: InputMaybe<IntFilter>;
  PM_BASIC_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  PM_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_CONTACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  PM_INSUR_END?: InputMaybe<StringNullableFilter>;
  PM_INSUR_POLICENUM?: InputMaybe<StringNullableFilter>;
  PM_INSUR_PRODUCT?: InputMaybe<StringNullableFilter>;
  PM_INSUR_START?: InputMaybe<StringNullableFilter>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  PM_PERSONAL_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_PHONE?: InputMaybe<StringNullableFilter>;
  PM_PLATE_NUM?: InputMaybe<StringNullableFilter>;
  PM_PRIVATE_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<StringNullableFilter>;
  PM_WARRANTY_END?: InputMaybe<StringNullableFilter>;
  POINT_NAME?: InputMaybe<StringFilter>;
  POINT_PRIORITY?: InputMaybe<IntFilter>;
  POINT_TYPE?: InputMaybe<StringFilter>;
  REMINDER_ONE?: InputMaybe<DateTimeFilter>;
  REMINDER_THREE?: InputMaybe<DateTimeNullableFilter>;
  REMINDER_TWO?: InputMaybe<DateTimeNullableFilter>;
  SALESMAN?: InputMaybe<StringNullableFilter>;
  TRADE_IN?: InputMaybe<StringNullableFilter>;
  Vladelec_ID?: InputMaybe<IntNullableFilter>;
  carId?: InputMaybe<StringFilter>;
  clientId?: InputMaybe<StringFilter>;
  commentTemplatesId?: InputMaybe<StringNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastCommentAuthorId?: InputMaybe<StringNullableFilter>;
  officeId?: InputMaybe<StringFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  taskStatusId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TaskStatus = {
  __typename?: 'TaskStatus';
  NAME: Scalars['String'];
  ROLF_COMMENT_TYPE?: Maybe<Scalars['String']>;
  _count?: Maybe<TaskStatusCount>;
  addFACT_DATE: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  finalStatus: Scalars['Boolean'];
  id: Scalars['String'];
  isDateTransfer?: Maybe<Scalars['Boolean']>;
  notChange: Scalars['Boolean'];
  taskStatusHistory: Array<TaskStatusHistory>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type TaskStatusTaskStatusHistoryArgs = {
  cursor?: InputMaybe<TaskStatusHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskStatusHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskStatusHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskStatusHistoryWhereInput>;
};


export type TaskStatusTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type TaskStatusCount = {
  __typename?: 'TaskStatusCount';
  taskStatusHistory: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type TaskStatusCreateInput = {
  NAME: Scalars['String'];
  ROLF_COMMENT_TYPE?: InputMaybe<Scalars['String']>;
  addFACT_DATE: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  finalStatus: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  isDateTransfer?: InputMaybe<Scalars['Boolean']>;
  notChange: Scalars['Boolean'];
  taskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskStatusInput>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutStatusInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TaskStatusCreateNestedOneWithoutTaskStatusHistoryInput = {
  connect?: InputMaybe<TaskStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskStatusCreateOrConnectWithoutTaskStatusHistoryInput>;
  create?: InputMaybe<TaskStatusCreateWithoutTaskStatusHistoryInput>;
};

export type TaskStatusCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<TaskStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskStatusCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<TaskStatusCreateWithoutTasksInput>;
};

export type TaskStatusCreateOrConnectWithoutTaskStatusHistoryInput = {
  create: TaskStatusCreateWithoutTaskStatusHistoryInput;
  where: TaskStatusWhereUniqueInput;
};

export type TaskStatusCreateOrConnectWithoutTasksInput = {
  create: TaskStatusCreateWithoutTasksInput;
  where: TaskStatusWhereUniqueInput;
};

export type TaskStatusCreateWithoutTaskStatusHistoryInput = {
  NAME: Scalars['String'];
  ROLF_COMMENT_TYPE?: InputMaybe<Scalars['String']>;
  addFACT_DATE: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  finalStatus: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  isDateTransfer?: InputMaybe<Scalars['Boolean']>;
  notChange: Scalars['Boolean'];
  tasks?: InputMaybe<TaskCreateNestedManyWithoutStatusInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TaskStatusCreateWithoutTasksInput = {
  NAME: Scalars['String'];
  ROLF_COMMENT_TYPE?: InputMaybe<Scalars['String']>;
  addFACT_DATE: Scalars['Boolean'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  finalStatus: Scalars['Boolean'];
  id?: InputMaybe<Scalars['String']>;
  isDateTransfer?: InputMaybe<Scalars['Boolean']>;
  notChange: Scalars['Boolean'];
  taskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutTaskStatusInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TaskStatusHistory = {
  __typename?: 'TaskStatusHistory';
  LAST_COMMENT_CLIENT?: Maybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  author?: Maybe<User>;
  authorId?: Maybe<Scalars['String']>;
  deletedAt: Scalars['DateTime'];
  id: Scalars['String'];
  task: Task;
  taskId: Scalars['String'];
  taskStatus?: Maybe<TaskStatus>;
  taskStatusId?: Maybe<Scalars['String']>;
};

export type TaskStatusHistoryCreateManyAuthorInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  taskId: Scalars['String'];
  taskStatusId?: InputMaybe<Scalars['String']>;
};

export type TaskStatusHistoryCreateManyAuthorInputEnvelope = {
  data: Array<TaskStatusHistoryCreateManyAuthorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskStatusHistoryCreateManyTaskInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  authorId?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  taskStatusId?: InputMaybe<Scalars['String']>;
};

export type TaskStatusHistoryCreateManyTaskInputEnvelope = {
  data: Array<TaskStatusHistoryCreateManyTaskInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskStatusHistoryCreateManyTaskStatusInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  authorId?: InputMaybe<Scalars['String']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  taskId: Scalars['String'];
};

export type TaskStatusHistoryCreateManyTaskStatusInputEnvelope = {
  data: Array<TaskStatusHistoryCreateManyTaskStatusInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TaskStatusHistoryCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyAuthorInputEnvelope>;
};

export type TaskStatusHistoryCreateNestedManyWithoutTaskInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutTaskInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutTaskInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyTaskInputEnvelope>;
};

export type TaskStatusHistoryCreateNestedManyWithoutTaskStatusInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutTaskStatusInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutTaskStatusInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyTaskStatusInputEnvelope>;
};

export type TaskStatusHistoryCreateOrConnectWithoutAuthorInput = {
  create: TaskStatusHistoryCreateWithoutAuthorInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryCreateOrConnectWithoutTaskInput = {
  create: TaskStatusHistoryCreateWithoutTaskInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryCreateOrConnectWithoutTaskStatusInput = {
  create: TaskStatusHistoryCreateWithoutTaskStatusInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryCreateWithoutAuthorInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  task: TaskCreateNestedOneWithoutStatusHistoryInput;
  taskStatus?: InputMaybe<TaskStatusCreateNestedOneWithoutTaskStatusHistoryInput>;
};

export type TaskStatusHistoryCreateWithoutTaskInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  author?: InputMaybe<UserCreateNestedOneWithoutTaskStatusHistoryInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  taskStatus?: InputMaybe<TaskStatusCreateNestedOneWithoutTaskStatusHistoryInput>;
};

export type TaskStatusHistoryCreateWithoutTaskStatusInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  NAME_STATUS: Scalars['String'];
  author?: InputMaybe<UserCreateNestedOneWithoutTaskStatusHistoryInput>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  task: TaskCreateNestedOneWithoutStatusHistoryInput;
};

export type TaskStatusHistoryListRelationFilter = {
  every?: InputMaybe<TaskStatusHistoryWhereInput>;
  none?: InputMaybe<TaskStatusHistoryWhereInput>;
  some?: InputMaybe<TaskStatusHistoryWhereInput>;
};

export type TaskStatusHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TaskStatusHistoryOrderByWithRelationInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<SortOrder>;
  NAME_STATUS?: InputMaybe<SortOrder>;
  author?: InputMaybe<UserOrderByWithRelationInput>;
  authorId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  task?: InputMaybe<TaskOrderByWithRelationInput>;
  taskId?: InputMaybe<SortOrder>;
  taskStatus?: InputMaybe<TaskStatusOrderByWithRelationInput>;
  taskStatusId?: InputMaybe<SortOrder>;
};

export enum TaskStatusHistoryScalarFieldEnum {
  LastCommentClient = 'LAST_COMMENT_CLIENT',
  NameStatus = 'NAME_STATUS',
  AuthorId = 'authorId',
  DeletedAt = 'deletedAt',
  Id = 'id',
  TaskId = 'taskId',
  TaskStatusId = 'taskStatusId'
}

export type TaskStatusHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  LAST_COMMENT_CLIENT?: InputMaybe<StringNullableFilter>;
  NAME_STATUS?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  authorId?: InputMaybe<StringNullableFilter>;
  deletedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  taskId?: InputMaybe<StringFilter>;
  taskStatusId?: InputMaybe<StringNullableFilter>;
};

export type TaskStatusHistoryUpdateManyMutationInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NAME_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TaskStatusHistoryUpdateManyWithWhereWithoutAuthorInput = {
  data: TaskStatusHistoryUpdateManyMutationInput;
  where: TaskStatusHistoryScalarWhereInput;
};

export type TaskStatusHistoryUpdateManyWithWhereWithoutTaskInput = {
  data: TaskStatusHistoryUpdateManyMutationInput;
  where: TaskStatusHistoryScalarWhereInput;
};

export type TaskStatusHistoryUpdateManyWithWhereWithoutTaskStatusInput = {
  data: TaskStatusHistoryUpdateManyMutationInput;
  where: TaskStatusHistoryScalarWhereInput;
};

export type TaskStatusHistoryUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskStatusHistoryUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<TaskStatusHistoryUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<TaskStatusHistoryUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type TaskStatusHistoryUpdateManyWithoutTaskInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutTaskInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutTaskInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyTaskInputEnvelope>;
  delete?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskStatusHistoryUpdateWithWhereUniqueWithoutTaskInput>>;
  updateMany?: InputMaybe<Array<TaskStatusHistoryUpdateManyWithWhereWithoutTaskInput>>;
  upsert?: InputMaybe<Array<TaskStatusHistoryUpsertWithWhereUniqueWithoutTaskInput>>;
};

export type TaskStatusHistoryUpdateManyWithoutTaskStatusInput = {
  connect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskStatusHistoryCreateOrConnectWithoutTaskStatusInput>>;
  create?: InputMaybe<Array<TaskStatusHistoryCreateWithoutTaskStatusInput>>;
  createMany?: InputMaybe<TaskStatusHistoryCreateManyTaskStatusInputEnvelope>;
  delete?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskStatusHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskStatusHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskStatusHistoryUpdateWithWhereUniqueWithoutTaskStatusInput>>;
  updateMany?: InputMaybe<Array<TaskStatusHistoryUpdateManyWithWhereWithoutTaskStatusInput>>;
  upsert?: InputMaybe<Array<TaskStatusHistoryUpsertWithWhereUniqueWithoutTaskStatusInput>>;
};

export type TaskStatusHistoryUpdateWithWhereUniqueWithoutAuthorInput = {
  data: TaskStatusHistoryUpdateWithoutAuthorInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryUpdateWithWhereUniqueWithoutTaskInput = {
  data: TaskStatusHistoryUpdateWithoutTaskInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryUpdateWithWhereUniqueWithoutTaskStatusInput = {
  data: TaskStatusHistoryUpdateWithoutTaskStatusInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryUpdateWithoutAuthorInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NAME_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneRequiredWithoutStatusHistoryInput>;
  taskStatus?: InputMaybe<TaskStatusUpdateOneWithoutTaskStatusHistoryInput>;
};

export type TaskStatusHistoryUpdateWithoutTaskInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NAME_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  author?: InputMaybe<UserUpdateOneWithoutTaskStatusHistoryInput>;
  deletedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  taskStatus?: InputMaybe<TaskStatusUpdateOneWithoutTaskStatusHistoryInput>;
};

export type TaskStatusHistoryUpdateWithoutTaskStatusInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NAME_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  author?: InputMaybe<UserUpdateOneWithoutTaskStatusHistoryInput>;
  deletedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  task?: InputMaybe<TaskUpdateOneRequiredWithoutStatusHistoryInput>;
};

export type TaskStatusHistoryUpsertWithWhereUniqueWithoutAuthorInput = {
  create: TaskStatusHistoryCreateWithoutAuthorInput;
  update: TaskStatusHistoryUpdateWithoutAuthorInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryUpsertWithWhereUniqueWithoutTaskInput = {
  create: TaskStatusHistoryCreateWithoutTaskInput;
  update: TaskStatusHistoryUpdateWithoutTaskInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryUpsertWithWhereUniqueWithoutTaskStatusInput = {
  create: TaskStatusHistoryCreateWithoutTaskStatusInput;
  update: TaskStatusHistoryUpdateWithoutTaskStatusInput;
  where: TaskStatusHistoryWhereUniqueInput;
};

export type TaskStatusHistoryWhereInput = {
  AND?: InputMaybe<Array<TaskStatusHistoryWhereInput>>;
  LAST_COMMENT_CLIENT?: InputMaybe<StringNullableFilter>;
  NAME_STATUS?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TaskStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<TaskStatusHistoryWhereInput>>;
  author?: InputMaybe<UserRelationFilter>;
  authorId?: InputMaybe<StringNullableFilter>;
  deletedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  task?: InputMaybe<TaskRelationFilter>;
  taskId?: InputMaybe<StringFilter>;
  taskStatus?: InputMaybe<TaskStatusRelationFilter>;
  taskStatusId?: InputMaybe<StringNullableFilter>;
};

export type TaskStatusHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TaskStatusNamerolf_Comment_TypeFinalStatusCompoundUniqueInput = {
  NAME: Scalars['String'];
  ROLF_COMMENT_TYPE: Scalars['String'];
  finalStatus: Scalars['Boolean'];
};

export type TaskStatusOrderByWithRelationInput = {
  NAME?: InputMaybe<SortOrder>;
  ROLF_COMMENT_TYPE?: InputMaybe<SortOrder>;
  addFACT_DATE?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  finalStatus?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDateTransfer?: InputMaybe<SortOrder>;
  notChange?: InputMaybe<SortOrder>;
  taskStatusHistory?: InputMaybe<TaskStatusHistoryOrderByRelationAggregateInput>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TaskStatusRelationFilter = {
  is?: InputMaybe<TaskStatusWhereInput>;
  isNot?: InputMaybe<TaskStatusWhereInput>;
};

export enum TaskStatusScalarFieldEnum {
  Name = 'NAME',
  RolfCommentType = 'ROLF_COMMENT_TYPE',
  AddFactDate = 'addFACT_DATE',
  CreatedAt = 'createdAt',
  FinalStatus = 'finalStatus',
  Id = 'id',
  IsDateTransfer = 'isDateTransfer',
  NotChange = 'notChange',
  UpdatedAt = 'updatedAt'
}

export type TaskStatusUpdateInput = {
  NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  ROLF_COMMENT_TYPE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addFACT_DATE?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  finalStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDateTransfer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskStatusInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutStatusInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaskStatusUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<TaskStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskStatusCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<TaskStatusCreateWithoutTasksInput>;
  update?: InputMaybe<TaskStatusUpdateWithoutTasksInput>;
  upsert?: InputMaybe<TaskStatusUpsertWithoutTasksInput>;
};

export type TaskStatusUpdateOneWithoutTaskStatusHistoryInput = {
  connect?: InputMaybe<TaskStatusWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskStatusCreateOrConnectWithoutTaskStatusHistoryInput>;
  create?: InputMaybe<TaskStatusCreateWithoutTaskStatusHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TaskStatusUpdateWithoutTaskStatusHistoryInput>;
  upsert?: InputMaybe<TaskStatusUpsertWithoutTaskStatusHistoryInput>;
};

export type TaskStatusUpdateWithoutTaskStatusHistoryInput = {
  NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  ROLF_COMMENT_TYPE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addFACT_DATE?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  finalStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDateTransfer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutStatusInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaskStatusUpdateWithoutTasksInput = {
  NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  ROLF_COMMENT_TYPE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  addFACT_DATE?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  finalStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDateTransfer?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  taskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskStatusInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TaskStatusUpsertWithoutTaskStatusHistoryInput = {
  create: TaskStatusCreateWithoutTaskStatusHistoryInput;
  update: TaskStatusUpdateWithoutTaskStatusHistoryInput;
};

export type TaskStatusUpsertWithoutTasksInput = {
  create: TaskStatusCreateWithoutTasksInput;
  update: TaskStatusUpdateWithoutTasksInput;
};

export type TaskStatusWhereInput = {
  AND?: InputMaybe<Array<TaskStatusWhereInput>>;
  NAME?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TaskStatusWhereInput>>;
  OR?: InputMaybe<Array<TaskStatusWhereInput>>;
  ROLF_COMMENT_TYPE?: InputMaybe<StringNullableFilter>;
  addFACT_DATE?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  finalStatus?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  isDateTransfer?: InputMaybe<BoolNullableFilter>;
  notChange?: InputMaybe<BoolFilter>;
  taskStatusHistory?: InputMaybe<TaskStatusHistoryListRelationFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TaskStatusWhereUniqueInput = {
  NAME?: InputMaybe<Scalars['String']>;
  NAME_ROLF_COMMENT_TYPE_finalStatus?: InputMaybe<TaskStatusNamerolf_Comment_TypeFinalStatusCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type TaskUpdateManyMutationInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type TaskUpdateManyWithWhereWithoutCarInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutClientInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutCommentTemplateInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutLastCommentAuthorInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutManagerInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutOfficeInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutPointInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithWhereWithoutStatusInput = {
  data: TaskUpdateManyMutationInput;
  where: TaskScalarWhereInput;
};

export type TaskUpdateManyWithoutCarInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCarInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCarInput>>;
  createMany?: InputMaybe<TaskCreateManyCarInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutCarInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutCarInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutCarInput>>;
};

export type TaskUpdateManyWithoutClientInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutClientInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutClientInput>>;
  createMany?: InputMaybe<TaskCreateManyClientInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutClientInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutClientInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutClientInput>>;
};

export type TaskUpdateManyWithoutCommentTemplateInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutCommentTemplateInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutCommentTemplateInput>>;
  createMany?: InputMaybe<TaskCreateManyCommentTemplateInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutCommentTemplateInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutCommentTemplateInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutCommentTemplateInput>>;
};

export type TaskUpdateManyWithoutLastCommentAuthorInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutLastCommentAuthorInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutLastCommentAuthorInput>>;
  createMany?: InputMaybe<TaskCreateManyLastCommentAuthorInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutLastCommentAuthorInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutLastCommentAuthorInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutLastCommentAuthorInput>>;
};

export type TaskUpdateManyWithoutManagerInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutManagerInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutManagerInput>>;
  createMany?: InputMaybe<TaskCreateManyManagerInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutManagerInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutManagerInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutManagerInput>>;
};

export type TaskUpdateManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<TaskCreateManyOfficeInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutOfficeInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutOfficeInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutOfficeInput>>;
};

export type TaskUpdateManyWithoutPointInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutPointInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutPointInput>>;
  createMany?: InputMaybe<TaskCreateManyPointInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutPointInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutPointInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutPointInput>>;
};

export type TaskUpdateManyWithoutStatusInput = {
  connect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TaskCreateOrConnectWithoutStatusInput>>;
  create?: InputMaybe<Array<TaskCreateWithoutStatusInput>>;
  createMany?: InputMaybe<TaskCreateManyStatusInputEnvelope>;
  delete?: InputMaybe<Array<TaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TaskWhereUniqueInput>>;
  set?: InputMaybe<Array<TaskWhereUniqueInput>>;
  update?: InputMaybe<Array<TaskUpdateWithWhereUniqueWithoutStatusInput>>;
  updateMany?: InputMaybe<Array<TaskUpdateManyWithWhereWithoutStatusInput>>;
  upsert?: InputMaybe<Array<TaskUpsertWithWhereUniqueWithoutStatusInput>>;
};

export type TaskUpdateOneRequiredWithoutStatusHistoryInput = {
  connect?: InputMaybe<TaskWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TaskCreateOrConnectWithoutStatusHistoryInput>;
  create?: InputMaybe<TaskCreateWithoutStatusHistoryInput>;
  update?: InputMaybe<TaskUpdateWithoutStatusHistoryInput>;
  upsert?: InputMaybe<TaskUpsertWithoutStatusHistoryInput>;
};

export type TaskUpdateWithWhereUniqueWithoutCarInput = {
  data: TaskUpdateWithoutCarInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutClientInput = {
  data: TaskUpdateWithoutClientInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutCommentTemplateInput = {
  data: TaskUpdateWithoutCommentTemplateInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutLastCommentAuthorInput = {
  data: TaskUpdateWithoutLastCommentAuthorInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutManagerInput = {
  data: TaskUpdateWithoutManagerInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutOfficeInput = {
  data: TaskUpdateWithoutOfficeInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutPointInput = {
  data: TaskUpdateWithoutPointInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithWhereUniqueWithoutStatusInput = {
  data: TaskUpdateWithoutStatusInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpdateWithoutCarInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutClientInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutCommentTemplateInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutLastCommentAuthorInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutManagerInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutOfficeInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutPointInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutStatusHistoryInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  status?: InputMaybe<TaskStatusUpdateOneRequiredWithoutTasksInput>;
};

export type TaskUpdateWithoutStatusInput = {
  BRAND?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_MODEL?: InputMaybe<StringFieldUpdateOperationsInput>;
  CAR_VIN?: InputMaybe<StringFieldUpdateOperationsInput>;
  CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_EMAIL?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLIENT_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CLOSE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CLOSE_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  COMMENT_OPTIONS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  COMMENT_TYPE_ONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CREDIT_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  CURRENT_STATUS_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  FACT_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  ID_COL?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID_COL_INT?: InputMaybe<IntFieldUpdateOperationsInput>;
  INFO?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  INSERT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  JURID_STATUS?: InputMaybe<StringFieldUpdateOperationsInput>;
  KASKO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  KPIS?: InputMaybe<StringFieldUpdateOperationsInput>;
  LAST_CHANGE_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  LAST_COMMENT_CLIENT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  LCR_ACCESS_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_EXTWARR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_KASKO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_OSAGO_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  LCR_PM_PERC?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MILEAGE?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  MODIFY_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  MODIFY_STATUS?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFieldUpdateOperationsInput>;
  OSAGO_NV_SALEDATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  PLAN_DATE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  PLAN_MONTH?: InputMaybe<IntFieldUpdateOperationsInput>;
  PLAN_PRED_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PLAN_YEAR?: InputMaybe<IntFieldUpdateOperationsInput>;
  PM_BASIC_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_ADDRESS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CLIENT_NAME?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_INSUR_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_POLICENUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_PRODUCT?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_INSUR_START?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  PM_PERSONAL_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PHONE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PLATE_NUM?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_PRIVATE_COMMENTS?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  PM_WARRANTY_END?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  POINT_NAME?: InputMaybe<StringFieldUpdateOperationsInput>;
  POINT_PRIORITY?: InputMaybe<IntFieldUpdateOperationsInput>;
  POINT_TYPE?: InputMaybe<StringFieldUpdateOperationsInput>;
  REMINDER_ONE?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  REMINDER_THREE?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  REMINDER_TWO?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  SALESMAN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TRADE_IN?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vladelec_ID?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  car?: InputMaybe<CarUpdateOneRequiredWithoutTasksInput>;
  client?: InputMaybe<ClientUpdateOneRequiredWithoutTasksInput>;
  commentTemplate?: InputMaybe<CommentTemplatesUpdateOneWithoutTasksInput>;
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutTasksInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastCommentAuthor?: InputMaybe<UserUpdateOneWithoutCommentedTasksInput>;
  manager?: InputMaybe<UserUpdateOneRequiredWithoutTasksInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutTasksInput>;
  point?: InputMaybe<PointTypeUpdateOneRequiredWithoutTasksInput>;
  statusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutTaskInput>;
};

export type TaskUpsertWithWhereUniqueWithoutCarInput = {
  create: TaskCreateWithoutCarInput;
  update: TaskUpdateWithoutCarInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutClientInput = {
  create: TaskCreateWithoutClientInput;
  update: TaskUpdateWithoutClientInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutCommentTemplateInput = {
  create: TaskCreateWithoutCommentTemplateInput;
  update: TaskUpdateWithoutCommentTemplateInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutLastCommentAuthorInput = {
  create: TaskCreateWithoutLastCommentAuthorInput;
  update: TaskUpdateWithoutLastCommentAuthorInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutManagerInput = {
  create: TaskCreateWithoutManagerInput;
  update: TaskUpdateWithoutManagerInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutOfficeInput = {
  create: TaskCreateWithoutOfficeInput;
  update: TaskUpdateWithoutOfficeInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutPointInput = {
  create: TaskCreateWithoutPointInput;
  update: TaskUpdateWithoutPointInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithWhereUniqueWithoutStatusInput = {
  create: TaskCreateWithoutStatusInput;
  update: TaskUpdateWithoutStatusInput;
  where: TaskWhereUniqueInput;
};

export type TaskUpsertWithoutStatusHistoryInput = {
  create: TaskCreateWithoutStatusHistoryInput;
  update: TaskUpdateWithoutStatusHistoryInput;
};

export type TaskWhereInput = {
  AND?: InputMaybe<Array<TaskWhereInput>>;
  BRAND?: InputMaybe<StringFilter>;
  CAR_MODEL?: InputMaybe<StringFilter>;
  CAR_VIN?: InputMaybe<StringFilter>;
  CLIENT?: InputMaybe<StringNullableFilter>;
  CLIENT_EMAIL?: InputMaybe<StringNullableFilter>;
  CLIENT_OR_PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  CLIENT_PHONE?: InputMaybe<StringNullableFilter>;
  CLOSE_DATE?: InputMaybe<DateTimeNullableFilter>;
  CLOSE_STATUS?: InputMaybe<IntNullableFilter>;
  COMMENT_OPTIONS?: InputMaybe<StringNullableFilter>;
  COMMENT_TYPE_ONE?: InputMaybe<StringNullableFilter>;
  CREDIT?: InputMaybe<StringNullableFilter>;
  CREDIT_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  CRM_FACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  CRM_PLAN_DATE?: InputMaybe<DateTimeFilter>;
  CURRENT_STATUS_DATE?: InputMaybe<DateTimeNullableFilter>;
  FACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  FACT_PRED_DATE?: InputMaybe<DateTimeNullableFilter>;
  ID_COL?: InputMaybe<StringFilter>;
  ID_COL_INT?: InputMaybe<IntFilter>;
  INFO?: InputMaybe<StringNullableFilter>;
  INSERT_DATE?: InputMaybe<DateTimeNullableFilter>;
  JURID_STATUS?: InputMaybe<StringFilter>;
  KASKO_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  KPIS?: InputMaybe<StringFilter>;
  LAST_CHANGE_DATE?: InputMaybe<DateTimeNullableFilter>;
  LAST_COMMENT_CLIENT?: InputMaybe<StringNullableFilter>;
  LCR_ACCESS_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_EXTWARR_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_KASKO_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_OSAGO_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_PERC?: InputMaybe<FloatNullableFilter>;
  LCR_PM_PERC?: InputMaybe<FloatNullableFilter>;
  MILEAGE?: InputMaybe<FloatNullableFilter>;
  MODIFY_DATE?: InputMaybe<DateTimeNullableFilter>;
  MODIFY_STATUS?: InputMaybe<IntNullableFilter>;
  NOT?: InputMaybe<Array<TaskWhereInput>>;
  NUMBER_MONTHS_AFTER_ISSUANCE?: InputMaybe<FloatFilter>;
  OR?: InputMaybe<Array<TaskWhereInput>>;
  OSAGO_NV_SALEDATE?: InputMaybe<DateTimeNullableFilter>;
  PERIOD_10K_KM_AFTER_AFTER_TO?: InputMaybe<FloatNullableFilter>;
  PERIOD_10K_KM_BEFORE_AFTER_TO?: InputMaybe<FloatNullableFilter>;
  PLAN_DATE?: InputMaybe<DateTimeFilter>;
  PLAN_MONTH?: InputMaybe<IntFilter>;
  PLAN_PRED_DATE?: InputMaybe<DateTimeNullableFilter>;
  PLAN_YEAR?: InputMaybe<IntFilter>;
  PM_BASIC_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_ADDRESS?: InputMaybe<StringNullableFilter>;
  PM_CLIENT_NAME?: InputMaybe<StringNullableFilter>;
  PM_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_CONTACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  PM_INSUR_END?: InputMaybe<StringNullableFilter>;
  PM_INSUR_POLICENUM?: InputMaybe<StringNullableFilter>;
  PM_INSUR_PRODUCT?: InputMaybe<StringNullableFilter>;
  PM_INSUR_START?: InputMaybe<StringNullableFilter>;
  PM_NEXT_CONTACT_DATE?: InputMaybe<DateTimeNullableFilter>;
  PM_PERSONAL_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_PHONE?: InputMaybe<StringNullableFilter>;
  PM_PLATE_NUM?: InputMaybe<StringNullableFilter>;
  PM_PRIVATE_COMMENTS?: InputMaybe<StringNullableFilter>;
  PM_VEHICLE_REGISTRATION_PLATE?: InputMaybe<StringNullableFilter>;
  PM_WARRANTY_END?: InputMaybe<StringNullableFilter>;
  POINT_NAME?: InputMaybe<StringFilter>;
  POINT_PRIORITY?: InputMaybe<IntFilter>;
  POINT_TYPE?: InputMaybe<StringFilter>;
  REMINDER_ONE?: InputMaybe<DateTimeFilter>;
  REMINDER_THREE?: InputMaybe<DateTimeNullableFilter>;
  REMINDER_TWO?: InputMaybe<DateTimeNullableFilter>;
  SALESMAN?: InputMaybe<StringNullableFilter>;
  TRADE_IN?: InputMaybe<StringNullableFilter>;
  Vladelec_ID?: InputMaybe<IntNullableFilter>;
  car?: InputMaybe<CarRelationFilter>;
  carId?: InputMaybe<StringFilter>;
  client?: InputMaybe<ClientRelationFilter>;
  clientId?: InputMaybe<StringFilter>;
  commentTemplate?: InputMaybe<CommentTemplatesRelationFilter>;
  commentTemplatesId?: InputMaybe<StringNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  lastCommentAuthor?: InputMaybe<UserRelationFilter>;
  lastCommentAuthorId?: InputMaybe<StringNullableFilter>;
  manager?: InputMaybe<UserRelationFilter>;
  office?: InputMaybe<OfficeRelationFilter>;
  officeId?: InputMaybe<StringFilter>;
  point?: InputMaybe<PointTypeRelationFilter>;
  pointTypeId?: InputMaybe<StringFilter>;
  status?: InputMaybe<TaskStatusRelationFilter>;
  statusHistory?: InputMaybe<TaskStatusHistoryListRelationFilter>;
  taskStatusId?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type TaskWhereUniqueInput = {
  CAR_VIN_POINT_PRIORITY_POINT_TYPE?: InputMaybe<TaskCar_Vinpoint_Prioritypoint_TypeCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type UpdateCarManagerInput = {
  carId: Scalars['String'];
  comment: Scalars['String'];
  managerId: Scalars['String'];
};

export type UpdateClientDescriptionInput = {
  clientId: Scalars['String'];
  description: Scalars['String'];
};

export type UpdateTaskStatusInput = {
  LAST_COMMENT_CLIENT?: InputMaybe<Scalars['String']>;
  PLAN_DATE?: InputMaybe<Scalars['DateTime']>;
  commentTemplateId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  statusId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory: Array<TaskStatusHistory>;
  _count?: Maybe<UserCount>;
  cars: Array<Car>;
  clients: Array<Client>;
  commentedTasks: Array<Task>;
  createdAt: Scalars['DateTime'];
  director?: Maybe<User>;
  directorId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  login: Scalars['String'];
  office: Office;
  officeId: Scalars['String'];
  roles: Array<Role>;
  staff: Array<User>;
  surName?: Maybe<Scalars['String']>;
  tasks: Array<Task>;
  updatedAt: Scalars['DateTime'];
};


export type UserTaskStatusHistoryArgs = {
  cursor?: InputMaybe<TaskStatusHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskStatusHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskStatusHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskStatusHistoryWhereInput>;
};


export type UserCarsArgs = {
  cursor?: InputMaybe<CarWhereUniqueInput>;
  distinct?: InputMaybe<Array<CarScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CarOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CarWhereInput>;
};


export type UserClientsArgs = {
  cursor?: InputMaybe<ClientWhereUniqueInput>;
  distinct?: InputMaybe<Array<ClientScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClientWhereInput>;
};


export type UserCommentedTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};


export type UserRolesArgs = {
  cursor?: InputMaybe<RoleWhereUniqueInput>;
  distinct?: InputMaybe<Array<RoleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RoleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RoleWhereInput>;
};


export type UserStaffArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type UserTasksArgs = {
  cursor?: InputMaybe<TaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<TaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TaskWhereInput>;
};

export type UserCount = {
  __typename?: 'UserCount';
  TaskStatusHistory: Scalars['Int'];
  cars: Scalars['Int'];
  clients: Scalars['Int'];
  commentedTasks: Scalars['Int'];
  roles: Scalars['Int'];
  staff: Scalars['Int'];
  tasks: Scalars['Int'];
};

export type UserCreateInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyDirectorInput = {
  PERSONAL_MANAGER: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  officeId: Scalars['String'];
  password: Scalars['String'];
  surName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyDirectorInputEnvelope = {
  data: Array<UserCreateManyDirectorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyOfficeInput = {
  PERSONAL_MANAGER: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  directorId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password: Scalars['String'];
  surName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyOfficeInputEnvelope = {
  data: Array<UserCreateManyOfficeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutDirectorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutDirectorInput>>;
  create?: InputMaybe<Array<UserCreateWithoutDirectorInput>>;
  createMany?: InputMaybe<UserCreateManyDirectorInputEnvelope>;
};

export type UserCreateNestedManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<UserCreateManyOfficeInputEnvelope>;
};

export type UserCreateNestedManyWithoutRolesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRolesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRolesInput>>;
};

export type UserCreateNestedOneWithoutCarsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<UserCreateWithoutCarsInput>;
};

export type UserCreateNestedOneWithoutClientsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClientsInput>;
  create?: InputMaybe<UserCreateWithoutClientsInput>;
};

export type UserCreateNestedOneWithoutCommentedTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentedTasksInput>;
  create?: InputMaybe<UserCreateWithoutCommentedTasksInput>;
};

export type UserCreateNestedOneWithoutStaffInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStaffInput>;
  create?: InputMaybe<UserCreateWithoutStaffInput>;
};

export type UserCreateNestedOneWithoutTaskStatusHistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTaskStatusHistoryInput>;
  create?: InputMaybe<UserCreateWithoutTaskStatusHistoryInput>;
};

export type UserCreateNestedOneWithoutTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<UserCreateWithoutTasksInput>;
};

export type UserCreateOrConnectWithoutCarsInput = {
  create: UserCreateWithoutCarsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClientsInput = {
  create: UserCreateWithoutClientsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentedTasksInput = {
  create: UserCreateWithoutCommentedTasksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDirectorInput = {
  create: UserCreateWithoutDirectorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOfficeInput = {
  create: UserCreateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRolesInput = {
  create: UserCreateWithoutRolesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStaffInput = {
  create: UserCreateWithoutStaffInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTaskStatusHistoryInput = {
  create: UserCreateWithoutTaskStatusHistoryInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTasksInput = {
  create: UserCreateWithoutTasksInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCarsInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutClientsInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutCommentedTasksInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDirectorInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutOfficeInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutRolesInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutStaffInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutTaskStatusHistoryInput = {
  PERSONAL_MANAGER: Scalars['String'];
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<TaskCreateNestedManyWithoutManagerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutTasksInput = {
  PERSONAL_MANAGER: Scalars['String'];
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryCreateNestedManyWithoutAuthorInput>;
  cars?: InputMaybe<CarCreateNestedManyWithoutUserInput>;
  clients?: InputMaybe<ClientCreateNestedManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskCreateNestedManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  director?: InputMaybe<UserCreateNestedOneWithoutStaffInput>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  login: Scalars['String'];
  office: OfficeCreateNestedOneWithoutStaffInput;
  password: Scalars['String'];
  roles?: InputMaybe<RoleCreateNestedManyWithoutUsersInput>;
  staff?: InputMaybe<UserCreateNestedManyWithoutDirectorInput>;
  surName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  PERSONAL_MANAGER?: InputMaybe<SortOrder>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryOrderByRelationAggregateInput>;
  cars?: InputMaybe<CarOrderByRelationAggregateInput>;
  clients?: InputMaybe<ClientOrderByRelationAggregateInput>;
  commentedTasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  director?: InputMaybe<UserOrderByWithRelationInput>;
  directorId?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  login?: InputMaybe<SortOrder>;
  office?: InputMaybe<OfficeOrderByWithRelationInput>;
  officeId?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  roles?: InputMaybe<RoleOrderByRelationAggregateInput>;
  staff?: InputMaybe<UserOrderByRelationAggregateInput>;
  surName?: InputMaybe<SortOrder>;
  tasks?: InputMaybe<TaskOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  PersonalManager = 'PERSONAL_MANAGER',
  CreatedAt = 'createdAt',
  DirectorId = 'directorId',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Login = 'login',
  OfficeId = 'officeId',
  Password = 'password',
  SurName = 'surName',
  UpdatedAt = 'updatedAt'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  PERSONAL_MANAGER?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  directorId?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  login?: InputMaybe<StringFilter>;
  officeId?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  surName?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserUpdateInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutDirectorInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutOfficeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutRolesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutDirectorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutDirectorInput>>;
  create?: InputMaybe<Array<UserCreateWithoutDirectorInput>>;
  createMany?: InputMaybe<UserCreateManyDirectorInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutDirectorInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutDirectorInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutDirectorInput>>;
};

export type UserUpdateManyWithoutOfficeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutOfficeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutOfficeInput>>;
  createMany?: InputMaybe<UserCreateManyOfficeInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutOfficeInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutOfficeInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutOfficeInput>>;
};

export type UserUpdateManyWithoutRolesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRolesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRolesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutRolesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutRolesInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutRolesInput>>;
};

export type UserUpdateOneRequiredWithoutClientsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClientsInput>;
  create?: InputMaybe<UserCreateWithoutClientsInput>;
  update?: InputMaybe<UserUpdateWithoutClientsInput>;
  upsert?: InputMaybe<UserUpsertWithoutClientsInput>;
};

export type UserUpdateOneRequiredWithoutTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTasksInput>;
  create?: InputMaybe<UserCreateWithoutTasksInput>;
  update?: InputMaybe<UserUpdateWithoutTasksInput>;
  upsert?: InputMaybe<UserUpsertWithoutTasksInput>;
};

export type UserUpdateOneWithoutCarsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCarsInput>;
  create?: InputMaybe<UserCreateWithoutCarsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCarsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCarsInput>;
};

export type UserUpdateOneWithoutCommentedTasksInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentedTasksInput>;
  create?: InputMaybe<UserCreateWithoutCommentedTasksInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCommentedTasksInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentedTasksInput>;
};

export type UserUpdateOneWithoutStaffInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutStaffInput>;
  create?: InputMaybe<UserCreateWithoutStaffInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutStaffInput>;
  upsert?: InputMaybe<UserUpsertWithoutStaffInput>;
};

export type UserUpdateOneWithoutTaskStatusHistoryInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTaskStatusHistoryInput>;
  create?: InputMaybe<UserCreateWithoutTaskStatusHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutTaskStatusHistoryInput>;
  upsert?: InputMaybe<UserUpsertWithoutTaskStatusHistoryInput>;
};

export type UserUpdateWithWhereUniqueWithoutDirectorInput = {
  data: UserUpdateWithoutDirectorInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutOfficeInput = {
  data: UserUpdateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutRolesInput = {
  data: UserUpdateWithoutRolesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCarsInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutClientsInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommentedTasksInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDirectorInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOfficeInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRolesInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutStaffInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTaskStatusHistoryInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tasks?: InputMaybe<TaskUpdateManyWithoutManagerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTasksInput = {
  PERSONAL_MANAGER?: InputMaybe<StringFieldUpdateOperationsInput>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryUpdateManyWithoutAuthorInput>;
  cars?: InputMaybe<CarUpdateManyWithoutUserInput>;
  clients?: InputMaybe<ClientUpdateManyWithoutManagerInput>;
  commentedTasks?: InputMaybe<TaskUpdateManyWithoutLastCommentAuthorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  director?: InputMaybe<UserUpdateOneWithoutStaffInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  login?: InputMaybe<StringFieldUpdateOperationsInput>;
  office?: InputMaybe<OfficeUpdateOneRequiredWithoutStaffInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  roles?: InputMaybe<RoleUpdateManyWithoutUsersInput>;
  staff?: InputMaybe<UserUpdateManyWithoutDirectorInput>;
  surName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutDirectorInput = {
  create: UserCreateWithoutDirectorInput;
  update: UserUpdateWithoutDirectorInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutOfficeInput = {
  create: UserCreateWithoutOfficeInput;
  update: UserUpdateWithoutOfficeInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutRolesInput = {
  create: UserCreateWithoutRolesInput;
  update: UserUpdateWithoutRolesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutCarsInput = {
  create: UserCreateWithoutCarsInput;
  update: UserUpdateWithoutCarsInput;
};

export type UserUpsertWithoutClientsInput = {
  create: UserCreateWithoutClientsInput;
  update: UserUpdateWithoutClientsInput;
};

export type UserUpsertWithoutCommentedTasksInput = {
  create: UserCreateWithoutCommentedTasksInput;
  update: UserUpdateWithoutCommentedTasksInput;
};

export type UserUpsertWithoutStaffInput = {
  create: UserCreateWithoutStaffInput;
  update: UserUpdateWithoutStaffInput;
};

export type UserUpsertWithoutTaskStatusHistoryInput = {
  create: UserCreateWithoutTaskStatusHistoryInput;
  update: UserUpdateWithoutTaskStatusHistoryInput;
};

export type UserUpsertWithoutTasksInput = {
  create: UserCreateWithoutTasksInput;
  update: UserUpdateWithoutTasksInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  PERSONAL_MANAGER?: InputMaybe<StringFilter>;
  TaskStatusHistory?: InputMaybe<TaskStatusHistoryListRelationFilter>;
  cars?: InputMaybe<CarListRelationFilter>;
  clients?: InputMaybe<ClientListRelationFilter>;
  commentedTasks?: InputMaybe<TaskListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  director?: InputMaybe<UserRelationFilter>;
  directorId?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  login?: InputMaybe<StringFilter>;
  office?: InputMaybe<OfficeRelationFilter>;
  officeId?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  roles?: InputMaybe<RoleListRelationFilter>;
  staff?: InputMaybe<UserListRelationFilter>;
  surName?: InputMaybe<StringNullableFilter>;
  tasks?: InputMaybe<TaskListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  PERSONAL_MANAGER?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  login?: InputMaybe<Scalars['String']>;
};

export type VersionResponse = {
  __typename?: 'VersionResponse';
  version?: Maybe<Scalars['String']>;
};

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'ApiUser', id: string, lastName?: string | null, firstName?: string | null, surName?: string | null, login: string, roles: Array<{ __typename?: 'Role', name: RoleName, description?: string | null }>, staff?: Array<{ __typename?: 'ApiUser', id: string, login: string }> | null } };

export type SignInMutationVariables = Exact<{
  login: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignInMutation = { __typename?: 'Mutation', signIn: { __typename?: 'ApiToken', token: string, user: { __typename?: 'ApiUser', id: string } } };

export type UserAppFragment = { __typename?: 'User', id: string, lastName?: string | null, firstName?: string | null, surName?: string | null, login: string, roles: Array<{ __typename?: 'Role', name: RoleName, description?: string | null }>, staff: Array<{ __typename?: 'User', id: string, login: string }> };

export type AllClientsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput> | ClientOrderByWithRelationInput>;
  not?: InputMaybe<Scalars['String']>;
}>;


export type AllClientsQuery = { __typename?: 'Query', allClients: Array<{ __typename?: 'ApiClient', id: string, CLIENT?: string | null, PM_CLIENT_NAME?: string | null, cars?: Array<{ __typename?: 'Car', id: string, CAR_VIN: string }> | null }> };

export type ClientQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type ClientQuery = { __typename?: 'Query', client?: { __typename?: 'LcrClient', CLIENT?: string | null, CLIENT_EMAIL?: string | null, CLIENT_PHONE?: string | null, PM_BIRTHDATE?: any | null, PM_ADDRESS?: string | null, PM_CLIENT_NAME?: string | null, PM_FIO_DRIVER?: string | null, PM_FIO_WIFES_HUSBAND?: string | null, id: string, description?: string | null, lcr?: number | null, cars: Array<{ __typename?: 'Car', id: string, CAR_VIN: string, CAR_EQUIPMENT: string, CAR_MODEL: string, DATE_RELEASE: any, BRAND?: string | null, SALESMAN?: string | null, PM_VEHICLE_REGISTRATION_PLATE?: string | null, lcr: number, commentUserId?: string | null, tasks: Array<{ __typename?: 'Task', LCR_PERC?: number | null, office: { __typename?: 'Office', NAME_AX: string } }>, user?: { __typename?: 'User', login: string, id: string } | null }>, manager: { __typename?: 'User', id: string, login: string }, tasks: Array<{ __typename?: 'Task', LCR_PERC?: number | null, PLAN_DATE: any, statusHistory: Array<{ __typename?: 'TaskStatusHistory', taskStatusId?: string | null, LAST_COMMENT_CLIENT?: string | null, deletedAt: any, id: string }>, point: { __typename?: 'PointType', POINT_NAME: string, POINT_TYPE: string } }> } | null };

export type ClientLinkTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientLinkTypesQuery = { __typename?: 'Query', clientLinkTypes: Array<{ __typename?: 'ClientLinkType', name: string, id: string }> };

export type ClientLinksQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type ClientLinksQuery = { __typename?: 'Query', clientLinks: Array<{ __typename?: 'ApiClientLink', id: string, userId: string, link: { __typename?: 'ApiClient', id: string, CLIENT?: string | null, cars?: Array<{ __typename?: 'Car', id: string }> | null }, self: { __typename?: 'ApiClient', id: string, CLIENT?: string | null }, linkType: { __typename?: 'ApiClientLinkType', id: string, name: string, antonymId?: string | null } }> };

export type ClientsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClientOrderByWithRelationInput> | ClientOrderByWithRelationInput>;
  not?: InputMaybe<Scalars['String']>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'ClientList', id: string, CLIENT?: string | null, PM_CLIENT_NAME?: string | null, lcr: number, managers: Array<{ __typename?: 'User', login: string }> }> };

export type CreateManyClientLinkMutationVariables = Exact<{
  data: Array<ApiClientLinkCreateInput> | ApiClientLinkCreateInput;
}>;


export type CreateManyClientLinkMutation = { __typename?: 'Mutation', createManyClientLink: { __typename?: 'AffectedRowsOutput', count: number } };

export type DeleteManyClientLinkMutationVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type DeleteManyClientLinkMutation = { __typename?: 'Mutation', deleteManyClientLink: { __typename?: 'AffectedRowsOutput', count: number } };

export type UpdateCarManagerMutationVariables = Exact<{
  carId: Scalars['String'];
  managerId: Scalars['String'];
  comment: Scalars['String'];
}>;


export type UpdateCarManagerMutation = { __typename?: 'Mutation', updateCarManager?: { __typename?: 'Car', client: { __typename?: 'Client', manager: { __typename?: 'User', id: string, login: string } } } | null };

export type UpdateClientDescriptionMutationVariables = Exact<{
  clientId: Scalars['String'];
  description: Scalars['String'];
}>;


export type UpdateClientDescriptionMutation = { __typename?: 'Mutation', updateClientDescription?: { __typename?: 'Client', id: string, PM_CLIENT_NAME?: string | null, description?: string | null } | null };

export type CommentTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type CommentTemplatesQuery = { __typename?: 'Query', findManyCommentTemplates: Array<{ __typename?: 'CommentTemplates', id: string, CODE: string, CATEGORY: string, TEXT: string }> };

export type PointQueryVariables = Exact<{ [key: string]: never; }>;


export type PointQuery = { __typename?: 'Query', pointTypes: Array<{ __typename?: 'PointType', POINT_TYPE: string, POINT_NAME: string }> };

export type ExecuteReportMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type ExecuteReportMutation = { __typename?: 'Mutation', executeReport: { __typename?: 'ApiReport', name: string, fileUrl: string } };

export type ReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'Report', name: string, description: string, id: string }> };

export type TaskStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type TaskStatusesQuery = { __typename?: 'Query', taskStatuses: Array<{ __typename?: 'TaskStatus', id: string, NAME: string, finalStatus: boolean }> };

export type CalendarTaskListItemFragment = { __typename?: 'TaskList', id: string, ID_COL: string, CRM_PLAN_DATE: any, client: { __typename?: 'TaskListClient', CLIENT_PHONE?: string | null, PM_CLIENT_NAME?: string | null, CLIENT?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string }, car: { __typename?: 'Car', CAR_MODEL: string, BRAND?: string | null, telematic?: { __typename?: 'CarTelematic', mileage?: number | null } | null }, status: { __typename?: 'TaskStatus', id: string, NAME: string } };

export type CalendarTasksQueryVariables = Exact<{
  managerId?: InputMaybe<Scalars['String']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  statusId?: InputMaybe<Scalars['String']>;
}>;


export type CalendarTasksQuery = { __typename?: 'Query', tasks: Array<{ __typename?: 'TaskList', id: string, ID_COL: string, CRM_PLAN_DATE: any, client: { __typename?: 'TaskListClient', CLIENT_PHONE?: string | null, PM_CLIENT_NAME?: string | null, CLIENT?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string }, car: { __typename?: 'Car', CAR_MODEL: string, BRAND?: string | null, telematic?: { __typename?: 'CarTelematic', mileage?: number | null } | null }, status: { __typename?: 'TaskStatus', id: string, NAME: string } }> };

export type TaskQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type TaskQuery = { __typename?: 'Query', task?: { __typename?: 'Task', id: string, ID_COL: string, CRM_PLAN_DATE: any, CRM_FACT_DATE?: any | null, PLAN_YEAR: number, PLAN_MONTH: number, MILEAGE?: number | null, LAST_COMMENT_CLIENT?: string | null, CURRENT_STATUS_DATE?: any | null, POINT_PRIORITY: number, point: { __typename?: 'PointType', POINT_NAME: string, POINT_TYPE: string, commentTemplates: Array<{ __typename?: 'PointTypeCommentTemplate', commentTemplates: { __typename?: 'CommentTemplates', id: string, TEXT: string, CATEGORY: string } }> }, lastCommentAuthor?: { __typename?: 'User', login: string } | null, status: { __typename?: 'TaskStatus', id: string, NAME: string, ROLF_COMMENT_TYPE?: string | null }, statusHistory: Array<{ __typename?: 'TaskStatusHistory', id: string, deletedAt: any, taskStatusId?: string | null, LAST_COMMENT_CLIENT?: string | null, NAME_STATUS: string, author?: { __typename?: 'User', login: string } | null }>, client: { __typename?: 'Client', id: string, CLIENT_PHONE?: string | null, PM_CLIENT_NAME?: string | null, CLIENT?: string | null }, car: { __typename?: 'Car', CAR_MODEL: string, CAR_EQUIPMENT: string, CAR_VIN: string, DATE_RELEASE: any, BRAND?: string | null, telematic?: { __typename?: 'CarTelematic', mileage?: number | null } | null }, company: { __typename?: 'Company', ORDER_COMPANY: string }, office: { __typename?: 'Office', CITY: string, DEALERID: string, NAME_AX: string } } | null };

export type TaskCardFragment = { __typename?: 'Task', id: string, ID_COL: string, CRM_PLAN_DATE: any, CRM_FACT_DATE?: any | null, PLAN_YEAR: number, PLAN_MONTH: number, MILEAGE?: number | null, LAST_COMMENT_CLIENT?: string | null, CURRENT_STATUS_DATE?: any | null, POINT_PRIORITY: number, lastCommentAuthor?: { __typename?: 'User', login: string } | null, status: { __typename?: 'TaskStatus', id: string, NAME: string, ROLF_COMMENT_TYPE?: string | null }, statusHistory: Array<{ __typename?: 'TaskStatusHistory', id: string, deletedAt: any, taskStatusId?: string | null, LAST_COMMENT_CLIENT?: string | null, NAME_STATUS: string, author?: { __typename?: 'User', login: string } | null }>, client: { __typename?: 'Client', id: string, CLIENT_PHONE?: string | null, PM_CLIENT_NAME?: string | null, CLIENT?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string, POINT_TYPE: string }, car: { __typename?: 'Car', CAR_MODEL: string, CAR_EQUIPMENT: string, CAR_VIN: string, DATE_RELEASE: any, BRAND?: string | null, telematic?: { __typename?: 'CarTelematic', mileage?: number | null } | null }, company: { __typename?: 'Company', ORDER_COMPANY: string }, office: { __typename?: 'Office', CITY: string, DEALERID: string, NAME_AX: string } };

export type TaskStatusUpdateMutationVariables = Exact<{
  input: UpdateTaskStatusInput;
}>;


export type TaskStatusUpdateMutation = { __typename?: 'Mutation', taskStatusUpdate?: { __typename?: 'Task', id: string, ID_COL: string, CRM_PLAN_DATE: any, CRM_FACT_DATE?: any | null, PLAN_YEAR: number, PLAN_MONTH: number, MILEAGE?: number | null, LAST_COMMENT_CLIENT?: string | null, CURRENT_STATUS_DATE?: any | null, POINT_PRIORITY: number, lastCommentAuthor?: { __typename?: 'User', login: string } | null, status: { __typename?: 'TaskStatus', id: string, NAME: string, ROLF_COMMENT_TYPE?: string | null }, statusHistory: Array<{ __typename?: 'TaskStatusHistory', id: string, deletedAt: any, taskStatusId?: string | null, LAST_COMMENT_CLIENT?: string | null, NAME_STATUS: string, author?: { __typename?: 'User', login: string } | null }>, client: { __typename?: 'Client', id: string, CLIENT_PHONE?: string | null, PM_CLIENT_NAME?: string | null, CLIENT?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string, POINT_TYPE: string }, car: { __typename?: 'Car', CAR_MODEL: string, CAR_EQUIPMENT: string, CAR_VIN: string, DATE_RELEASE: any, BRAND?: string | null, telematic?: { __typename?: 'CarTelematic', mileage?: number | null } | null }, company: { __typename?: 'Company', ORDER_COMPANY: string }, office: { __typename?: 'Office', CITY: string, DEALERID: string, NAME_AX: string } } | null };

export type TaskListItemFragment = { __typename?: 'TaskList', id: string, ID_COL: string, CRM_PLAN_DATE: any, CURRENT_STATUS_DATE?: any | null, CLIENT_OR_PM_CLIENT_NAME?: string | null, POINT_PRIORITY?: number | null, client: { __typename?: 'TaskListClient', CLIENT_PHONE?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string }, car: { __typename?: 'Car', CAR_MODEL: string, BRAND?: string | null }, status: { __typename?: 'TaskStatus', id: string, NAME: string } };

export type TasksQueryVariables = Exact<{
  managerId?: InputMaybe<Scalars['String']>;
  periodStart?: InputMaybe<Scalars['DateTime']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  search?: InputMaybe<Scalars['String']>;
  statusId?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TaskOrderByWithRelationInput> | TaskOrderByWithRelationInput>;
}>;


export type TasksQuery = { __typename?: 'Query', tasks: Array<{ __typename?: 'TaskList', id: string, ID_COL: string, CRM_PLAN_DATE: any, CURRENT_STATUS_DATE?: any | null, CLIENT_OR_PM_CLIENT_NAME?: string | null, POINT_PRIORITY?: number | null, client: { __typename?: 'TaskListClient', CLIENT_PHONE?: string | null }, point: { __typename?: 'PointType', POINT_NAME: string }, car: { __typename?: 'Car', CAR_MODEL: string, BRAND?: string | null }, status: { __typename?: 'TaskStatus', id: string, NAME: string } }> };

export type TasksByClientQueryVariables = Exact<{
  periodStart?: InputMaybe<Scalars['DateTime']>;
  periodEnd?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
  clientId?: InputMaybe<Scalars['String']>;
  pointType?: InputMaybe<Scalars['String']>;
  carId?: InputMaybe<Scalars['String']>;
}>;


export type TasksByClientQuery = { __typename?: 'Query', tasks: Array<{ __typename?: 'TaskList', LAST_COMMENT_CLIENT?: string | null, PLAN_DATE: any, CRM_PLAN_DATE: any, CURRENT_STATUS_DATE?: any | null, ID_COL: string, id: string, statusHistory: Array<{ __typename?: 'TaskStatusHistory', deletedAt: any, LAST_COMMENT_CLIENT?: string | null, taskStatusId?: string | null }>, status: { __typename?: 'TaskStatus', id: string }, point: { __typename?: 'PointType', POINT_TYPE: string, POINT_NAME: string } }> };

export const UserAppFragmentDoc = gql`
    fragment userApp on User {
  id
  lastName
  firstName
  surName
  login
  roles {
    name
    description
  }
  staff {
    id
    login
  }
}
    `;
export const CalendarTaskListItemFragmentDoc = gql`
    fragment calendarTaskListItem on TaskList {
  id
  ID_COL
  CRM_PLAN_DATE
  client {
    CLIENT_PHONE
    PM_CLIENT_NAME
    CLIENT
  }
  point {
    POINT_NAME
  }
  car {
    CAR_MODEL
    BRAND
    telematic {
      mileage
    }
  }
  status {
    id
    NAME
  }
}
    `;
export const TaskCardFragmentDoc = gql`
    fragment taskCard on Task {
  id
  ID_COL
  CRM_PLAN_DATE
  CRM_FACT_DATE
  PLAN_YEAR
  PLAN_MONTH
  MILEAGE
  LAST_COMMENT_CLIENT
  CURRENT_STATUS_DATE
  POINT_PRIORITY
  lastCommentAuthor {
    login
  }
  status {
    id
    NAME
    ROLF_COMMENT_TYPE
  }
  statusHistory(orderBy: {deletedAt: desc}) {
    id
    deletedAt
    taskStatusId
    LAST_COMMENT_CLIENT
    NAME_STATUS
    author {
      login
    }
  }
  client {
    id
    CLIENT_PHONE
    PM_CLIENT_NAME
    CLIENT
  }
  point {
    POINT_NAME
    POINT_TYPE
  }
  car {
    CAR_MODEL
    CAR_EQUIPMENT
    CAR_VIN
    DATE_RELEASE
    BRAND
    telematic {
      mileage
    }
  }
  company {
    ORDER_COMPANY
  }
  office {
    CITY
    DEALERID
    NAME_AX
  }
}
    `;
export const TaskListItemFragmentDoc = gql`
    fragment taskListItem on TaskList {
  id
  ID_COL
  CRM_PLAN_DATE
  CURRENT_STATUS_DATE
  CLIENT_OR_PM_CLIENT_NAME
  POINT_PRIORITY
  client {
    CLIENT_PHONE
  }
  point {
    POINT_NAME
  }
  car {
    CAR_MODEL
    BRAND
  }
  status {
    id
    NAME
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    id
    lastName
    firstName
    surName
    login
    roles {
      name
      description
    }
    staff {
      id
      login
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SignInDocument = gql`
    mutation signIn($login: String!, $password: String!) {
  signIn(input: {login: $login, password: $password}) {
    token
    user {
      id
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      login: // value for 'login'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const AllClientsDocument = gql`
    query allClients($search: String, $take: Int = 100, $skip: Int, $orderBy: [ClientOrderByWithRelationInput!], $not: String) {
  allClients(
    take: $take
    skip: $skip
    where: {AND: [{OR: [{CLIENT: {contains: $search, mode: insensitive}}, {PM_CLIENT_NAME: {contains: $search, mode: insensitive}}, {cars: {some: {OR: [{CAR_VIN: {contains: $search, mode: insensitive}}, {PM_VEHICLE_REGISTRATION_PLATE: {contains: $search, mode: insensitive}}]}}}]}, {NOT: [{id: {equals: $not, mode: insensitive}}]}]}
    orderBy: $orderBy
  ) {
    id
    CLIENT
    PM_CLIENT_NAME
    cars {
      id
      CAR_VIN
    }
  }
}
    `;

/**
 * __useAllClientsQuery__
 *
 * To run a query within a React component, call `useAllClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllClientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      not: // value for 'not'
 *   },
 * });
 */
export function useAllClientsQuery(baseOptions?: Apollo.QueryHookOptions<AllClientsQuery, AllClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllClientsQuery, AllClientsQueryVariables>(AllClientsDocument, options);
      }
export function useAllClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllClientsQuery, AllClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllClientsQuery, AllClientsQueryVariables>(AllClientsDocument, options);
        }
export type AllClientsQueryHookResult = ReturnType<typeof useAllClientsQuery>;
export type AllClientsLazyQueryHookResult = ReturnType<typeof useAllClientsLazyQuery>;
export type AllClientsQueryResult = Apollo.QueryResult<AllClientsQuery, AllClientsQueryVariables>;
export const ClientDocument = gql`
    query client($id: String) {
  client(where: {id: $id}) {
    CLIENT
    CLIENT_EMAIL
    CLIENT_PHONE
    PM_BIRTHDATE
    PM_ADDRESS
    PM_CLIENT_NAME
    PM_FIO_DRIVER
    PM_FIO_WIFES_HUSBAND
    id
    description
    lcr
    cars {
      id
      CAR_VIN
      CAR_EQUIPMENT
      CAR_MODEL
      DATE_RELEASE
      BRAND
      SALESMAN
      PM_VEHICLE_REGISTRATION_PLATE
      lcr
      tasks {
        office {
          NAME_AX
        }
        LCR_PERC
      }
      user {
        login
        id
      }
      commentUserId
    }
    manager {
      id
      login
    }
    tasks {
      LCR_PERC
      PLAN_DATE
      statusHistory {
        taskStatusId
        LAST_COMMENT_CLIENT
        deletedAt
        id
      }
      point {
        POINT_NAME
        POINT_TYPE
      }
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(baseOptions?: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientLinkTypesDocument = gql`
    query clientLinkTypes {
  clientLinkTypes {
    name
    id
  }
}
    `;

/**
 * __useClientLinkTypesQuery__
 *
 * To run a query within a React component, call `useClientLinkTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLinkTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLinkTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientLinkTypesQuery(baseOptions?: Apollo.QueryHookOptions<ClientLinkTypesQuery, ClientLinkTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientLinkTypesQuery, ClientLinkTypesQueryVariables>(ClientLinkTypesDocument, options);
      }
export function useClientLinkTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientLinkTypesQuery, ClientLinkTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientLinkTypesQuery, ClientLinkTypesQueryVariables>(ClientLinkTypesDocument, options);
        }
export type ClientLinkTypesQueryHookResult = ReturnType<typeof useClientLinkTypesQuery>;
export type ClientLinkTypesLazyQueryHookResult = ReturnType<typeof useClientLinkTypesLazyQuery>;
export type ClientLinkTypesQueryResult = Apollo.QueryResult<ClientLinkTypesQuery, ClientLinkTypesQueryVariables>;
export const ClientLinksDocument = gql`
    query clientLinks($id: String) {
  clientLinks(id: $id) {
    id
    userId
    link {
      id
      CLIENT
      cars {
        id
      }
    }
    self {
      id
      CLIENT
    }
    linkType {
      id
      name
      antonymId
    }
  }
}
    `;

/**
 * __useClientLinksQuery__
 *
 * To run a query within a React component, call `useClientLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientLinksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientLinksQuery(baseOptions?: Apollo.QueryHookOptions<ClientLinksQuery, ClientLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientLinksQuery, ClientLinksQueryVariables>(ClientLinksDocument, options);
      }
export function useClientLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientLinksQuery, ClientLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientLinksQuery, ClientLinksQueryVariables>(ClientLinksDocument, options);
        }
export type ClientLinksQueryHookResult = ReturnType<typeof useClientLinksQuery>;
export type ClientLinksLazyQueryHookResult = ReturnType<typeof useClientLinksLazyQuery>;
export type ClientLinksQueryResult = Apollo.QueryResult<ClientLinksQuery, ClientLinksQueryVariables>;
export const ClientsDocument = gql`
    query clients($search: String, $take: Int = 100, $skip: Int, $orderBy: [ClientOrderByWithRelationInput!], $not: String) {
  clients(
    take: $take
    skip: $skip
    where: {AND: [{OR: [{CLIENT: {contains: $search, mode: insensitive}}, {PM_CLIENT_NAME: {contains: $search, mode: insensitive}}, {cars: {some: {OR: [{CAR_VIN: {contains: $search, mode: insensitive}}, {PM_VEHICLE_REGISTRATION_PLATE: {contains: $search, mode: insensitive}}]}}}]}, {NOT: [{id: {equals: $not, mode: insensitive}}]}]}
    orderBy: $orderBy
  ) {
    id
    CLIENT
    PM_CLIENT_NAME
    managers {
      login
    }
    lcr
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *      not: // value for 'not'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const CreateManyClientLinkDocument = gql`
    mutation createManyClientLink($data: [ApiClientLinkCreateInput!]!) {
  createManyClientLink(data: $data) {
    count
  }
}
    `;
export type CreateManyClientLinkMutationFn = Apollo.MutationFunction<CreateManyClientLinkMutation, CreateManyClientLinkMutationVariables>;

/**
 * __useCreateManyClientLinkMutation__
 *
 * To run a mutation, you first call `useCreateManyClientLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyClientLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyClientLinkMutation, { data, loading, error }] = useCreateManyClientLinkMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManyClientLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyClientLinkMutation, CreateManyClientLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyClientLinkMutation, CreateManyClientLinkMutationVariables>(CreateManyClientLinkDocument, options);
      }
export type CreateManyClientLinkMutationHookResult = ReturnType<typeof useCreateManyClientLinkMutation>;
export type CreateManyClientLinkMutationResult = Apollo.MutationResult<CreateManyClientLinkMutation>;
export type CreateManyClientLinkMutationOptions = Apollo.BaseMutationOptions<CreateManyClientLinkMutation, CreateManyClientLinkMutationVariables>;
export const DeleteManyClientLinkDocument = gql`
    mutation deleteManyClientLink($ids: [String!]) {
  deleteManyClientLink(where: {id: {in: $ids}}) {
    count
  }
}
    `;
export type DeleteManyClientLinkMutationFn = Apollo.MutationFunction<DeleteManyClientLinkMutation, DeleteManyClientLinkMutationVariables>;

/**
 * __useDeleteManyClientLinkMutation__
 *
 * To run a mutation, you first call `useDeleteManyClientLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyClientLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyClientLinkMutation, { data, loading, error }] = useDeleteManyClientLinkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteManyClientLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyClientLinkMutation, DeleteManyClientLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyClientLinkMutation, DeleteManyClientLinkMutationVariables>(DeleteManyClientLinkDocument, options);
      }
export type DeleteManyClientLinkMutationHookResult = ReturnType<typeof useDeleteManyClientLinkMutation>;
export type DeleteManyClientLinkMutationResult = Apollo.MutationResult<DeleteManyClientLinkMutation>;
export type DeleteManyClientLinkMutationOptions = Apollo.BaseMutationOptions<DeleteManyClientLinkMutation, DeleteManyClientLinkMutationVariables>;
export const UpdateCarManagerDocument = gql`
    mutation updateCarManager($carId: String!, $managerId: String!, $comment: String!) {
  updateCarManager(
    input: {carId: $carId, managerId: $managerId, comment: $comment}
  ) {
    client {
      manager {
        id
        login
      }
    }
  }
}
    `;
export type UpdateCarManagerMutationFn = Apollo.MutationFunction<UpdateCarManagerMutation, UpdateCarManagerMutationVariables>;

/**
 * __useUpdateCarManagerMutation__
 *
 * To run a mutation, you first call `useUpdateCarManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarManagerMutation, { data, loading, error }] = useUpdateCarManagerMutation({
 *   variables: {
 *      carId: // value for 'carId'
 *      managerId: // value for 'managerId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateCarManagerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCarManagerMutation, UpdateCarManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCarManagerMutation, UpdateCarManagerMutationVariables>(UpdateCarManagerDocument, options);
      }
export type UpdateCarManagerMutationHookResult = ReturnType<typeof useUpdateCarManagerMutation>;
export type UpdateCarManagerMutationResult = Apollo.MutationResult<UpdateCarManagerMutation>;
export type UpdateCarManagerMutationOptions = Apollo.BaseMutationOptions<UpdateCarManagerMutation, UpdateCarManagerMutationVariables>;
export const UpdateClientDescriptionDocument = gql`
    mutation updateClientDescription($clientId: String!, $description: String!) {
  updateClientDescription(input: {clientId: $clientId, description: $description}) {
    id
    PM_CLIENT_NAME
    description
  }
}
    `;
export type UpdateClientDescriptionMutationFn = Apollo.MutationFunction<UpdateClientDescriptionMutation, UpdateClientDescriptionMutationVariables>;

/**
 * __useUpdateClientDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateClientDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientDescriptionMutation, { data, loading, error }] = useUpdateClientDescriptionMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateClientDescriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientDescriptionMutation, UpdateClientDescriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientDescriptionMutation, UpdateClientDescriptionMutationVariables>(UpdateClientDescriptionDocument, options);
      }
export type UpdateClientDescriptionMutationHookResult = ReturnType<typeof useUpdateClientDescriptionMutation>;
export type UpdateClientDescriptionMutationResult = Apollo.MutationResult<UpdateClientDescriptionMutation>;
export type UpdateClientDescriptionMutationOptions = Apollo.BaseMutationOptions<UpdateClientDescriptionMutation, UpdateClientDescriptionMutationVariables>;
export const CommentTemplatesDocument = gql`
    query commentTemplates {
  findManyCommentTemplates(orderBy: {CODE: asc}) {
    id
    CODE
    CATEGORY
    TEXT
  }
}
    `;

/**
 * __useCommentTemplatesQuery__
 *
 * To run a query within a React component, call `useCommentTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommentTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<CommentTemplatesQuery, CommentTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentTemplatesQuery, CommentTemplatesQueryVariables>(CommentTemplatesDocument, options);
      }
export function useCommentTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentTemplatesQuery, CommentTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentTemplatesQuery, CommentTemplatesQueryVariables>(CommentTemplatesDocument, options);
        }
export type CommentTemplatesQueryHookResult = ReturnType<typeof useCommentTemplatesQuery>;
export type CommentTemplatesLazyQueryHookResult = ReturnType<typeof useCommentTemplatesLazyQuery>;
export type CommentTemplatesQueryResult = Apollo.QueryResult<CommentTemplatesQuery, CommentTemplatesQueryVariables>;
export const PointDocument = gql`
    query point {
  pointTypes {
    POINT_TYPE
    POINT_NAME
  }
}
    `;

/**
 * __usePointQuery__
 *
 * To run a query within a React component, call `usePointQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointQuery({
 *   variables: {
 *   },
 * });
 */
export function usePointQuery(baseOptions?: Apollo.QueryHookOptions<PointQuery, PointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PointQuery, PointQueryVariables>(PointDocument, options);
      }
export function usePointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PointQuery, PointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PointQuery, PointQueryVariables>(PointDocument, options);
        }
export type PointQueryHookResult = ReturnType<typeof usePointQuery>;
export type PointLazyQueryHookResult = ReturnType<typeof usePointLazyQuery>;
export type PointQueryResult = Apollo.QueryResult<PointQuery, PointQueryVariables>;
export const ExecuteReportDocument = gql`
    mutation executeReport($name: String!) {
  executeReport(data: {name: $name}) {
    name
    fileUrl
  }
}
    `;
export type ExecuteReportMutationFn = Apollo.MutationFunction<ExecuteReportMutation, ExecuteReportMutationVariables>;

/**
 * __useExecuteReportMutation__
 *
 * To run a mutation, you first call `useExecuteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeReportMutation, { data, loading, error }] = useExecuteReportMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useExecuteReportMutation(baseOptions?: Apollo.MutationHookOptions<ExecuteReportMutation, ExecuteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExecuteReportMutation, ExecuteReportMutationVariables>(ExecuteReportDocument, options);
      }
export type ExecuteReportMutationHookResult = ReturnType<typeof useExecuteReportMutation>;
export type ExecuteReportMutationResult = Apollo.MutationResult<ExecuteReportMutation>;
export type ExecuteReportMutationOptions = Apollo.BaseMutationOptions<ExecuteReportMutation, ExecuteReportMutationVariables>;
export const ReportsDocument = gql`
    query reports {
  reports {
    name
    description
    id
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const TaskStatusesDocument = gql`
    query taskStatuses {
  taskStatuses {
    id
    NAME
    finalStatus
  }
}
    `;

/**
 * __useTaskStatusesQuery__
 *
 * To run a query within a React component, call `useTaskStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskStatusesQuery(baseOptions?: Apollo.QueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, options);
      }
export function useTaskStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskStatusesQuery, TaskStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskStatusesQuery, TaskStatusesQueryVariables>(TaskStatusesDocument, options);
        }
export type TaskStatusesQueryHookResult = ReturnType<typeof useTaskStatusesQuery>;
export type TaskStatusesLazyQueryHookResult = ReturnType<typeof useTaskStatusesLazyQuery>;
export type TaskStatusesQueryResult = Apollo.QueryResult<TaskStatusesQuery, TaskStatusesQueryVariables>;
export const CalendarTasksDocument = gql`
    query calendarTasks($managerId: String, $periodStart: DateTime, $periodEnd: DateTime, $statusId: String) {
  tasks(
    orderBy: {id: asc}
    where: {AND: [{taskStatusId: {equals: $statusId}}, {userId: {equals: $managerId}}, {CRM_PLAN_DATE: {gte: $periodStart, lte: $periodEnd}}]}
  ) {
    ...calendarTaskListItem
  }
}
    ${CalendarTaskListItemFragmentDoc}`;

/**
 * __useCalendarTasksQuery__
 *
 * To run a query within a React component, call `useCalendarTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarTasksQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      statusId: // value for 'statusId'
 *   },
 * });
 */
export function useCalendarTasksQuery(baseOptions?: Apollo.QueryHookOptions<CalendarTasksQuery, CalendarTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarTasksQuery, CalendarTasksQueryVariables>(CalendarTasksDocument, options);
      }
export function useCalendarTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarTasksQuery, CalendarTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarTasksQuery, CalendarTasksQueryVariables>(CalendarTasksDocument, options);
        }
export type CalendarTasksQueryHookResult = ReturnType<typeof useCalendarTasksQuery>;
export type CalendarTasksLazyQueryHookResult = ReturnType<typeof useCalendarTasksLazyQuery>;
export type CalendarTasksQueryResult = Apollo.QueryResult<CalendarTasksQuery, CalendarTasksQueryVariables>;
export const TaskDocument = gql`
    query task($id: String) {
  task(where: {id: $id}) {
    ...taskCard
    point {
      POINT_NAME
      POINT_TYPE
      commentTemplates {
        commentTemplates {
          id
          TEXT
          CATEGORY
          CATEGORY
        }
      }
    }
  }
}
    ${TaskCardFragmentDoc}`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskQuery(baseOptions?: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TaskStatusUpdateDocument = gql`
    mutation taskStatusUpdate($input: UpdateTaskStatusInput!) {
  taskStatusUpdate(input: $input) {
    ...taskCard
  }
}
    ${TaskCardFragmentDoc}`;
export type TaskStatusUpdateMutationFn = Apollo.MutationFunction<TaskStatusUpdateMutation, TaskStatusUpdateMutationVariables>;

/**
 * __useTaskStatusUpdateMutation__
 *
 * To run a mutation, you first call `useTaskStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskStatusUpdateMutation, { data, loading, error }] = useTaskStatusUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskStatusUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TaskStatusUpdateMutation, TaskStatusUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskStatusUpdateMutation, TaskStatusUpdateMutationVariables>(TaskStatusUpdateDocument, options);
      }
export type TaskStatusUpdateMutationHookResult = ReturnType<typeof useTaskStatusUpdateMutation>;
export type TaskStatusUpdateMutationResult = Apollo.MutationResult<TaskStatusUpdateMutation>;
export type TaskStatusUpdateMutationOptions = Apollo.BaseMutationOptions<TaskStatusUpdateMutation, TaskStatusUpdateMutationVariables>;
export const TasksDocument = gql`
    query tasks($managerId: String, $periodStart: DateTime, $periodEnd: DateTime, $search: String, $statusId: String, $take: Int = 100, $skip: Int, $orderBy: [TaskOrderByWithRelationInput!]) {
  tasks(
    skip: $skip
    take: $take
    orderBy: $orderBy
    where: {AND: [{taskStatusId: {equals: $statusId}}, {userId: {equals: $managerId}}, {CRM_PLAN_DATE: {gte: $periodStart, lte: $periodEnd}}, {OR: [{ID_COL: {contains: $search, mode: insensitive}}, {CLIENT: {contains: $search, mode: insensitive}}, {PM_CLIENT_NAME: {contains: $search, mode: insensitive}}, {CAR_MODEL: {contains: $search, mode: insensitive}}, {BRAND: {contains: $search, mode: insensitive}}, {POINT_NAME: {contains: $search, mode: insensitive}}, {CAR_VIN: {contains: $search, mode: insensitive}}, {PM_VEHICLE_REGISTRATION_PLATE: {contains: $search, mode: insensitive}}]}]}
  ) {
    ...taskListItem
  }
}
    ${TaskListItemFragmentDoc}`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      managerId: // value for 'managerId'
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      search: // value for 'search'
 *      statusId: // value for 'statusId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksByClientDocument = gql`
    query tasksByClient($periodStart: DateTime, $periodEnd: DateTime, $take: Int = 100, $clientId: String, $pointType: String, $carId: String) {
  tasks(
    take: $take
    where: {AND: {clientId: {equals: $clientId}, PLAN_DATE: {gte: $periodStart, lte: $periodEnd}, point: {is: {POINT_TYPE: {equals: $pointType}}}, car: {is: {id: {equals: $carId}}}}}
    orderBy: {CRM_PLAN_DATE: desc}
  ) {
    statusHistory {
      deletedAt
      LAST_COMMENT_CLIENT
      taskStatusId
    }
    status {
      id
    }
    LAST_COMMENT_CLIENT
    PLAN_DATE
    CRM_PLAN_DATE
    CURRENT_STATUS_DATE
    ID_COL
    id
    point {
      POINT_TYPE
      POINT_NAME
    }
  }
}
    `;

/**
 * __useTasksByClientQuery__
 *
 * To run a query within a React component, call `useTasksByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByClientQuery({
 *   variables: {
 *      periodStart: // value for 'periodStart'
 *      periodEnd: // value for 'periodEnd'
 *      take: // value for 'take'
 *      clientId: // value for 'clientId'
 *      pointType: // value for 'pointType'
 *      carId: // value for 'carId'
 *   },
 * });
 */
export function useTasksByClientQuery(baseOptions?: Apollo.QueryHookOptions<TasksByClientQuery, TasksByClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksByClientQuery, TasksByClientQueryVariables>(TasksByClientDocument, options);
      }
export function useTasksByClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksByClientQuery, TasksByClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksByClientQuery, TasksByClientQueryVariables>(TasksByClientDocument, options);
        }
export type TasksByClientQueryHookResult = ReturnType<typeof useTasksByClientQuery>;
export type TasksByClientLazyQueryHookResult = ReturnType<typeof useTasksByClientLazyQuery>;
export type TasksByClientQueryResult = Apollo.QueryResult<TasksByClientQuery, TasksByClientQueryVariables>;